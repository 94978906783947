<template>
  <div class="hotspot-overlay"
    :class="[
      {'exquisite': $root.exquisite},
      {'cosmogonie': $root.cosmogonie},
      {'kids': $root.kids},
      {'eyewear': $root.eyewear}
    ]"
    v-if="hotspotContent">
    <div class="bg"></div>

    <div class="hotspot-scroller">
      <div
        :key="hotspotContent.title"
        class="hotspot-container pa-0"
        ref="productcontainer" :style="{transform: 'translateX(' + targetX + 'px)' }">

        <transition name="main-photo">
          <div class="main-image"
            v-if="currentSet.mainPhotoID"
            v-show="currentSet.mainPhotoID && mainPhotoLoaded">

            <transition name="main-photo">
              <img ref="mainPhoto"
                v-show="$root.host !== 'brandexperience' || ($root.host === 'brandexperience' && !isSecondPhoto)"
                :src="mainPhoto">
            </transition>

            <transition name="main-photo">
            <img
              ref="secondPhoto"
              v-if="$root.host === 'brandexperience' && secondPhotoLoaded"
              v-show="isSecondPhoto"
              :src="secondPhoto">
            </transition>

            <div class="nav-photo" v-if="$root.host === 'brandexperience' && secondPhotoLoaded">
              <span class="mr-3 circle pointer" :class="{'active': !isSecondPhoto}" @click="clickMainPhoto(0)"></span>
              <span class="mr-3 circle pointer" :class="{'active': isSecondPhoto}" @click="clickMainPhoto(1)"></span>
            </div>

            <div class="look brandexperience">
              <h4 class="number">LOOK {{getLookNumber(currentSet.mainPhotoID)}}</h4>
            </div>
          </div>
        </transition>
        <ContentGallery
          @load="onImageLoaded"
          @previousSet="onPreviousSet"
          @nextSet="onNextSet"
          @updateSet="onUpdateSet"
          v-if="hotspotContentProducts && showGallery"
          :inputcontent="currentProduct"
          :mainPhotoWidth="mainPhotoWidth"
          :isMultiple="hotspotContentProducts.length > 1"
          :totalSet="hotspotContentProducts.length"
          :currentSetId="currentSetId"
          :mainPhotoExist="currentSet.mainPhotoID ? true : false"></ContentGallery>

      </div>

      <div class="pointer close"
        @click.prevent="closeHotspotContent"
        :class="[
          $root.exquisite ? 'button-circle-yellow' : 'button-circle-gold',
          {'colorweb': $root.host === 'colorweb'},
          {'nocart': $root.cartIsLocked}
        ]">
        <!--<p class="back" v-if="$root.host === 'colorweb'">{{$root.language[$route.params.language].backToTour}}</p>-->
        <CloseSvg class="close-svg svg-stroke-white absolute-center"/>
      </div>
    </div>

    <div class="wishlist-container" v-if="!$root.userIsPress && !$root.userIsColorwebNoHotspot && !$root.cartIsLocked">
      <ButtonWishlist :purple="true"></ButtonWishlist>
    </div>
  </div>
</template>

<script>
import ButtonWishlist from '../atoms/ButtonWishlist.vue'
import ContentGallery from './ContentGallery.vue'
import { Viewport } from '@monogrid/vue-lib'
import GetHotspotContent from '@/graphql/GetHotspotContent.gql'
import CloseSvg from '@/assets/svg/close.svg'

export default {
  components: {
    CloseSvg, ContentGallery, ButtonWishlist
  },
  mixins: [Viewport],
  data () {
    return {
      scrollHeight: 0,
      targetX: 0,
      mainPhoto: '',
      mainPhotoLoaded: false,
      secondPhoto: '',
      secondPhotoLoaded: false,
      isSecondPhoto: false,
      currentSetId: 0,
      currentSet: null,
      currentProduct: [],
      mainPhotoWidth: 0,
      hotspotContent: null,
      hotspotContentProducts: [],
      allImageLoaded: null,
      showGallery: false
    }
  },

  apollo: {
    HotspotContent: {
      query: GetHotspotContent,
      skip () {
        return this.$route.params.hotspotContent === 'map'
      },
      variables () {
        return {
          slug: this.$route.params.hotspotContent
        }
      },
      async result () {
        if (this.HotspotContent && (!this.hotspotContent || (this.hotspotContent && (this.HotspotContent._slug !== this.hotspotContent._slug)))) {
          this.$events.emit('isLoadingEnd')

          this.hotspotContent = this.HotspotContent

          if (this.$root.host === 'brandexperience') {
            this.hotspotContentProducts = []
            for (let i = 0; i < this.hotspotContent.products.length; i++) {
              const set = this.hotspotContent.products[i]
              if (this.checkProductSet(set)) {
                this.hotspotContentProducts.push(set)
              }
            }
          } else {
            this.hotspotContentProducts = this.hotspotContent.products
          }
          this.imageLoaded = 0
          this.currentSetId = 0
          this.showGallery = true
          this.initProductSet()
        }
      }
    }
  },

  mounted () {
    this.dragging = false
    this.dragX = 0
  },

  watch: {
    viewPort (newValue, oldValue) {
      this.updateMainPhotoWidth(this.$refs.mainPhoto)
    },

    $route: function (value) {
      if (!this.$route.params.hotspotContent) {
        this.hotspotContent = null
      }
    },

    '$apollo.loading': function (value) {
      if (value) {
        console.log('restart content')
        this.hotspotContent = null
        this.$events.emit('isLoadingStart')
      }
    }
  },

  methods: {
    getLookNumber (mainPhotoID) {
      const lookArray = mainPhotoID.split('-')
      let lookID = parseInt(lookArray[3])

      if (this.$root.host === 'brandexperience' &&
        this.currentSet.pressLookID) {
        lookID = parseInt(this.currentSet.pressLookID)
      }
      return lookID
    },
    onUpdateSet (index) {
      this.currentSetId = index
      this.initProductSet()
    },
    onNextSet () {
      if (this.currentSetId < this.hotspotContentProducts.length - 1) {
        this.currentSetId++
        const productExist = this.checkProductSet(this.hotspotContentProducts[this.currentSetId])
        console.log(productExist)
        if (this.$root.host === 'brandexperience' && productExist === true) {
          this.initProductSet()
        } else if (this.$root.host === 'colorweb') {
          this.initProductSet()
        }
      }
    },
    onPreviousSet () {
      if (this.currentSetId > 0) {
        this.currentSetId--
        const productExist = this.checkProductSet(this.hotspotContentProducts[this.currentSetId])
        console.log(productExist)
        if (this.$root.host === 'brandexperience' && productExist === true) {
          this.initProductSet()
        } else if (this.$root.host === 'colorweb') {
          this.initProductSet()
        }
      }
    },

    checkProductSet (checkSet) {
      let setExist = false
      for (let i = 0; i < checkSet.product.length; i++) {
        const item = checkSet.product[i]
        if (this.$root.host === 'brandexperience' && this.$root.BECode && this.$root.BECode[item.STMACO]) {
          setExist = true
        }
      }
      return setExist
    },

    initProductSet () {
      this.currentSet = this.hotspotContentProducts[this.currentSetId]
      // console.log(this.currentSetId)
      this.currentProduct = this.currentSet.product
      this.loadMainImage()

      if (window.parent && this.$root.host === 'brandexperience') {
        if (this.hotspotContent.BECategory) {
          const msgArt = {
            event: 'getCategoryArticles',
            categoryId: this.hotspotContent.BECategory
          }
          console.log('Brand Experience POST MESSAGE', msgArt.event, msgArt)

          window.parent.postMessage(msgArt, '*')
        }
      }
    },

    closeHotspotContent () {
      this.showGallery = false
      this.hotspotContent = null

      this.$router.push(
        {
          name: 'Room',
          params: {}
        }
      )
    },
    async clickMainPhoto (id) {
      if (id === 0) {
        this.isSecondPhoto = false
        await this.$nextTick()

        this.updateMainPhotoWidth(this.$refs.mainPhoto)

        setTimeout(() => {
          this.updateMainPhotoWidth(this.$refs.mainPhoto)
        }, 250)
      }

      if (id === 1) {
        this.isSecondPhoto = true
        await this.$nextTick()

        this.updateMainPhotoWidth(this.$refs.secondPhoto)

        setTimeout(() => {
          this.updateMainPhotoWidth(this.$refs.secondPhoto)
        }, 250)
      }
    },
    async loadMainImage () {
      this.mainPhotoLoaded = false
      this.mainPhoto = ''
      this.secondPhotoLoaded = false
      this.secondPhoto = ''
      this.isSecondPhoto = false

      if (this.$root.host === 'brandexperience' &&
        this.currentSet.mainPhotoID &&
        this.currentSet.extraLookPhoto) {
        const photoEpisode = '/media/' + this.currentSet.extraLookPhoto.id
        this.mainPhoto = await this.$root.getImage(photoEpisode, false, false)
        this.secondPhoto = await this.$root.getImage(this.currentSet.mainPhotoID + '&typ=E&Dim=XL&Str=1&Crop=000000', true, true)
        this.mainPhotoLoaded = true
        this.secondPhotoLoaded = true

        await this.$nextTick()

        this.updateMainPhotoWidth(this.$refs.mainPhoto)

        setTimeout(() => {
          this.updateMainPhotoWidth(this.$refs.mainPhoto)
        }, 250)
      } else if (this.currentSet.mainPhotoID) {
        this.mainPhoto = await this.$root.getImage(this.currentSet.mainPhotoID + '&typ=E&Dim=XL&Str=1&Crop=000000', true, true)
        this.mainPhotoLoaded = true
        await this.$nextTick()

        this.updateMainPhotoWidth(this.$refs.mainPhoto)

        setTimeout(() => {
          this.updateMainPhotoWidth(this.$refs.mainPhoto)
        }, 250)
      } else {
        this.mainPhotoWidth = 0
      }
    },
    updateMainPhotoWidth (photo) {
      if (photo) {
        this.mainPhotoWidth = photo.clientWidth > 0 ? photo.clientWidth : this.mainPhotoWidth
        console.log(photo.clientWidth)
      }
    },
    onImageLoaded () {
      this.imageLoaded++

      if (this.imageLoaded === this.hotspotContentProducts.length) {
        this.allImageLoaded = true
        this.$events.emit('isLoadingEnd')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-photo {
  position: absolute;
  bottom: 90px;
  left: 40px;
  padding: 10px;

  .circle {
    width: 20px;
    height: 20px;
    border: 1px solid $c-red;
    border-radius: 20px;
    display: inline-block;

    &.active {
      background-color: $c-red;
    }
  }
}

.look {
  position: absolute;
  bottom: 40px;
  left: 40px;
  padding: 10px;
  background-color: $c-black;

  &.brandexperience {
    background-color: $c-black;
  }

  .number {
    color: $c-white;
  }
}

.eyewear {
  .bg {
    background-color: #e7e7e7;
  }

  .button-circle-gold {
    border: 1px solid $c-black;
    background: transparent;

    &:hover {
      background-color: $c-black;
      box-shadow: none;
    }

    svg {
      path { //stylelint-disable-line
        stroke: $c-black;
      }
    }
  }
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background-color: $c-black-85;

  @include breakpoint('xs-only') {
    background-color: $c-black;
  }
}

.hotspot-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.hotspot-scroller {
  @include breakpoint('xs-only') {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
  }
}

.hotspot-container {
  white-space: nowrap;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: transform 0.5s;
  pointer-events: none;

  @include breakpoint('xs-only') {
    position: relative;
    left: auto;
    height: fit-content;
  }
}

.portrait {
  .main-image {
    position: absolute;
    top: 50%;
    left: 0;
    height: 50%;
    transform: translate(-50%, -50%);
  }
}

.main-image {
  height: 100%;
  @include breakpoint('xs-only') {
    position: relative;
  }

  img {
    width: auto;
    height: calc(100% - 40px);
    top: 20px;
    left: 20px;
    position: absolute;

    @include breakpoint('xs-only') {
      width: 100%;
      height: auto;
    }
  }
}

.cosmogonie {
  .colorweb.close {
    .close-svg {
      stroke: $c-black;

      path { //stylelint-disable-line
        // stroke: $c-black;
        stroke: $c-white;
        stroke-width: 1px;
      }
    }
  }
}

.close {
  // background-color: $c-white;
  position: fixed !important;
  top: 15px;
  right: 100px;
  z-index: 1;

  @include breakpoint('xs-only') {
    position: absolute;
    top: 20px;
    right: 0;
    margin-top: 0;
    width: 40px;
    height: 40px;
    transform: translateX(0);
  }

  &.nocart {
    right: 10px;
  }

  &.colorweb {
    // background-color: $c-red;

    .close-svg {
      fill: $c-white;

      path {
        fill: $c-white;
      }
    }
  }

  .back {
    white-space: nowrap;
    position: absolute;
    right: 55px;
    top: 9px;
  }
}

.horizontal-wrapper {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
}

.wishlist-container {
  position: fixed;
  top: 0;
  right: 30px;
  z-index: 1;

  @include breakpoint('xs-only') {
    display: none;
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: top 1s $ease-out-quint;
}

.slide-enter,
.slide-leave-to {
  top: 100vh !important;
}

.main-photo-leave-active,
.main-photo-enter-active {
  transition: transform 1s $ease-out-quint 0.5s, opacity 1s $ease-out-quint 0.5s;
}

.main-photo-enter,
.main-photo-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
