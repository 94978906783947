<template>
  <div
    v-if="hotspot || exit"
    v-show="!isHidden && hotspot.room.backgroundImage360"
    class="hotspot-room absolute-center"
    :class="[
      {'exquisite': $root.exquisite},
      {'kids': $root.kids},
      {'cosmogonie': $root.cosmogonie},
      {'eyewear': $root.eyewear},
      {'arrow': isIcon('up') || isIcon('down') || isIcon('left') || isIcon('right')},
      {'direction': isIcon('next') || isIcon('prev') || isIcon('back')},
      {'inactive': !hotspot.room.backgroundImage360},
      {'no-border': isIcon('open') || isIcon('close') || isIcon('exit')}
    ]"
    @click="onClick">
    <div class="pointer button-open absolute-center">
      <div>
        <IconExit v-if="isIcon('exit')" class="absolute-center svg-fill-black"></IconExit>
        <IconClose v-else-if="isIcon('close')" class="absolute-center svg-stroke-black"></IconClose>
        <IconOpen v-else-if="isIcon('open')" class="absolute-center svg-stroke-black"></IconOpen>
        <IconArrowRight  v-else-if="isIcon('right')" class="absolute-center svg-fill-white"></IconArrowRight>
        <IconArrowLeft v-else-if="isIcon('left')" class="absolute-center svg-fill-white"></IconArrowLeft>
        <IconArrowDown v-else-if="isIcon('down')" class="absolute-center svg-fill-white"></IconArrowDown>
        <IconArrowUp  v-else-if="isIcon('up')" class="absolute-center svg-fill-white"></IconArrowUp>
        <IconPov v-else class="absolute-center svg-fill-black"></IconPov>
        <p v-if="isIcon('next')">NEXT</p>
        <p v-if="isIcon('prev') || isIcon('back')">BACK</p>
        <p v-if="isIcon('girl')">GIRL</p>
        <p v-if="isIcon('boy')">BOY</p>
        <p v-if="isIcon('baby')">BABY</p>
      </div>
    </div>
  </div>
</template>

<script>
import IconExit from '@/assets/svg/exit.svg'
import IconClose from '@/assets/svg/close-door.svg'
import IconOpen from '@/assets/svg/open.svg'
import IconPov from '@/assets/svg/icon-map.svg'
import IconArrowLeft from '@/assets/svg/arrow-left.svg'
import IconArrowRight from '@/assets/svg/arrow-right.svg'
import IconArrowUp from '@/assets/svg/arrow-up.svg'
import IconArrowDown from '@/assets/svg/arrow-down.svg'

export default {
  name: 'HotspotArtHtml',
  props: {
    hotspot: {
      type: Object,
      required: false
    },
    exit: {
      type: Boolean
    }
  },

  methods: {
    isIcon (type) {
      return this.hotspot.position.indexOf(type) > -1
    },
    onClick () {
      if (this.iconType === 'open' || this.iconType === 'close') {
        this.$events.emit('keepCameraSteady')
      } else {
        this.$events.emit('useCameraInitialPoint')
      }
      this.$router.push(
        {
          name: 'Room',
          params: {
            floor: this.$route.params.floor,
            roomName: this.hotspot.room._slug
          }
        }
      )
    }
  },

  data () {
    return {
      isHidden: false
    }
  },
  mounted () {
    console.log(this.hotspot)
    /* this.$events.on('onCloseHotspot', () => {
      this.isHidden = false
    })

    this.$events.on('onOpenHotspot', (hotspot) => {
      this.isHidden = true
    }) */
  },

  components: {
    IconArrowRight, IconArrowLeft, IconArrowUp, IconArrowDown, IconPov, IconClose, IconOpen, IconExit
  }
}
</script>

<style lang="scss" scoped>

.hotspot-room {
  width: 50px;
  height: 50px;

  border-radius: 25px;
  border: 3px solid $c-gold;
  background-color: $c-black;
  transition: background-color 0.5s;

  &.eyewear {
    border: 1px solid $c-white;
    width: 40px;
    height: 40px;

    &:hover {
      background-color: $c-white;
      box-shadow: 0 0 15px $c-white;

      .svg-fill-white {
        path, circle { //stylelint-disable-line
          stroke: $c-black !important;
        }
      }
    }
  }

  &.exquisite {
    border: 1px solid $c-white;
    background-color: $c-yellow;

    &.direction {
      border: 1px solid $c-white;
      background-color: $c-tosca;

      path,
      circle {
        stroke: $c-white;
        fill: transparent;
      }
    }

    &.arrow {
      path,
      circle {
        stroke: $c-white;
        fill: transparent;
      }
    }

    path,
    circle {
      stroke: $c-yellow;
      fill: $c-white;
    }

    &:hover {
      background-color: $c-white;
      box-shadow: 0 0 15px $c-white;
      border: 1px solid $c-yellow;

      &.direction {
        path, circle { //stylelint-disable-line
          stroke: $c-tosca !important;
          fill: transparent;
        }
      }

      path,
      circle {
        stroke: $c-white;
        fill: $c-yellow;
      }
    }
  }

  &.cosmogonie {
    border: 1px solid $c-white;
    background-color: $c-cream;

    &.direction {
      border: 1px solid $c-white;
      background-color: $c-black;

      path,
      circle {
        stroke: $c-cream;
        fill: transparent;
      }

      p {
        color: $c-cream;
      }
    }

    &.arrow {
      background-color: $c-black;
      border: 1px solid $c-white;

      path,
      circle {
        stroke: $c-cream;
        fill: transparent;
      }
    }

    path,
    circle {
      stroke: $c-cream;
      fill: $c-black;
    }

    &:hover {
      background-color: $c-white;
      box-shadow: 0 0 15px $c-white;
      border: 1px solid $c-cream;

      &.direction {
        path, circle { //stylelint-disable-line
          stroke: $c-black !important;
          fill: transparent;
        }
      }

      path,
      circle {
        stroke: $c-white;
        fill: $c-black;
      }
    }
  }

  &.inactive {
    display: none;
  }

  &.no-border {
    border-style: none;
  }

  svg {
    width: 25px;
    height: 25px;
    transition: all 0.25s;

    path,
    circle {
      stroke: $c-white;
    }
  }

  &:hover {
    background-color: $c-red;
    box-shadow: 0 0 15px $c-red;
    border: 1px solid $c-red;

    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.button-open {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  // background-color: $c-white-90;

  p {
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translate(-50%, 0);
    text-align: center;
    line-height: 1.4;
  }
}
</style>
