var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade-delay","appear":""}},[_c('div',{staticClass:"rooms",class:[
    { 'exquisite' : _vm.$root.exquisite },
    { 'kids' : _vm.$root.kids },
    { 'cosmogonie' : _vm.$root.cosmogonie }
    ]},_vm._l((_vm.getRooms),function(room,rindex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(room.positionInMap && room.roomName),expression:"room.positionInMap && room.roomName"}],key:rindex},[(room.roomName && room.roomName.backgroundImage360 && (!room.roomName.colorwebRetailOnly || (room.roomName.colorwebRetailOnly && _vm.$root.userIsColorwebRetail)))?_c('div',{staticClass:"room-container pointer",class:{
            'visited': (_vm.$route.params.roomName !== room.roomName._slug && _vm.visitedRooms.includes(room.roomName._slug)),
            'active': (_vm.$route.params.roomName === room.roomName._slug),
            'main': room.positionInMap.indexOf('main') > -1
          },style:({
            'left': _vm.parsePosition(room.positionInMap).x + '%',
            'top': _vm.parsePosition(room.positionInMap).y + '%'
          }),on:{"click":function($event){$event.stopPropagation();return _vm.onChangeRoom(_vm.floor._slug, room.roomName._slug)},"mouseover":function($event){return _vm.$emit('onRoomOver', room.roomName.enTitle)},"mouseout":function($event){return _vm.$emit('onRoomOver', '')}}},[_c('div',{staticClass:"room-icon",class:{'has-content': _vm.hasContent(room.roomName)}},[((_vm.$route.params.roomName === room.roomName._slug))?_c('IconPov',{staticClass:"icon pov svg-fill-white absolute-center"}):(_vm.visitedRooms.includes(room.roomName._slug) && (_vm.$route.params.roomName !== room.roomName._slug))?_c('IconCheck',{staticClass:"icon check svg-fill-brown absolute-center"}):_vm._e()],1)]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }