import packageConfig from '../package.json'

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import { Platform } from '@monogrid/js-utils'

import router from './plugins/router.js'
// import './plugins/analytics.js'
import './plugins/locale.js'
import VueMeta from 'vue-meta'
import VueEvents from 'vue-event-handler'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Preloader from './preloader'
import onlineApolloProvider from './services/CMS'
import QueryString from 'query-string'

// import CMSOffline from './services/CMSOffline'
// import cmsOfflineCatalog from './services/CMSOfflineCatalog.json'
// import cmsOfflineSchema from './services/CMSOfflineSchema.graphql'

// usage: https://vuetifyjs.com/
import vuetify from './plugins/vuetify'
// usage: https://github.com/declandewet/vue-meta
Vue.use(VueMeta)
// usage: https://github.com/sandeepk01/vue-event-handler
Vue.use(VueEvents)
// usage: https://github.com/imcvampire/vue-axios
Vue.use(VueAxios, axios)

//
//
// Initialization of the preloader app (placed on the HTML)
//
//
const preloaderInstance = new Vue(Preloader)

// management of languages
router.beforeEach((to, from, next) => {
  // set current language on change route
  preloaderInstance.visible = true
  if (packageConfig.multilanguage) {
    if (to.params.language) {
      Vue.config.language = to.params.language || 'en'
    }
  } else {
    Vue.config.language = 'en'
  }
  next()
})
router.afterEach((to, from) => {
  preloaderInstance.visible = false
})

let initialColorwebUser = null

async function getUserType () {
  const parsedSearch = QueryString.parse(location.search) || {}
  const parsedQuery = QueryString.parse(location.hash.substring(location.hash.indexOf('?'))) || {}
  const parsed = { ...parsedQuery, ...parsedSearch }
  const { host, token, slimwatch, showroom, colorwebUserType } = parsed

  if (slimwatch === 'true') {
    console.log('slimwatch')
    return ('slimwatch')
  }

  if (colorwebUserType && colorwebUserType === 'WHL05') {
    console.log('WHL05 slimwatch')
    return ('slimwatch')
  }

  if (!host || host !== 'colorweb' || !token || showroom === 'true') {
    return 'showroom'
  }

  try {
    const paramsUser = "[{'TOKEN' :'" + token + "' , 'STMACO':'', 'OP':'U'}]"
    const resultUser = await axios.get('https://showroomtool.kering.net/ADAM-API-GUCCIS/api/wishlist?Key=' + paramsUser)

    if (resultUser.data && resultUser.data.length > 0 && resultUser.data[0].TYPEXX === 'WHL05') {
      return 'slimwatch'
    } else {
      if (resultUser.data[0]) {
        initialColorwebUser = resultUser
      }
      return 'showroom'
    }
  } catch (_) {
    return 'showroom'
  }
}

getUserType()
  .then((type) => {
    if (type === 'slimwatch') {
      window.location.replace(window.location.origin + '/watches2022/index.html#/en/' + (window.location.search || (window.location.hash && window.location.hash.indexOf('?') > -1 ? window.location.hash.substring(location.hash.indexOf('?')) : '') || ''))
    } else {
      initShowroom()
    }
    return true
  })
  .catch(() => {
    initShowroom()
  })

//
//
// Initialization of the app (placed on the HTML)
//
//
/* eslint-disable no-new */
function initShowroom () {
  new Vue({
    el: '#app',
    router,
    vuetify,
    apolloProvider: onlineApolloProvider,
    // apolloProvider: process.env.SERVICE_STAGE !== 'production' ? onlineApolloProvider : new CMSOffline(cmsOfflineCatalog, cmsOfflineSchema).getProvider(),
    data () {
      return {
        preloader: preloaderInstance,
        wishlist: [],
        BECategory: [],
        BEProducts: [],
        userIsPress: false,
        colorwebUserType: '',
        userIsColorwebRetail: false,
        userIsColorwebNoHotspot: false,
        host: '',
        colorwebUser: initialColorwebUser,
        watches: false,
        kids: false,
        eyewear: false,
        exquisite: false,
        cosmogonie: false,
        twinsburg: false,
        twinsburgMenuOpen: false,
        quality: 'mid',
        parentUrl: '',
        visitedRooms: [],
        cartIsLocked: false,
        process: {
          env: process.env
        }
      }
    },

    watch: {
      visitedRooms (newValue, oldValue) {
        if (this.$root.allRooms && this.$root.allRooms.length > 0) {
          const perc = ((this.$root.visitedRooms.length / this.$root.allRooms) * 100).toFixed(0)
          console.log('percentage visit room', perc)
          if (window.firebase.analytics() && this.$root.host) {
            window.firebase.analytics().logEvent('Percentage Room Visited', {
              percent_room: perc,
              host: this.$root.host
            })
            console.log('Firebase logEvent', window.firebase.analytics())
          }
        }
      }
    },
    mounted () {
      this.$root.parentUrl = null
      this.getImageProxyURL = 'https://virtualtour-getimage-proxy.gucci.cn/'

      if (window.location.href.indexOf('dev.monogrid.keringapps') > -1) {
        this.getImageProxyURL = ' https://getimage.wwmis.gucci/'
      }

      this.getImageVPNProxyURL = 'https://getimage.wwmis.gucci/'
      this.cacheImage = {}
      this.cacheColorwebAPI = {}
      this.$root.userIsPress = false
      this.$root.multilook = false
      this.$root.watches = false
      this.$root.kids = false
      this.getQueryURL()
    },

    methods: {
      getQueryURL () {
        const parsedSearch = QueryString.parse(location.search) || {}
        const parsedQuery = QueryString.parse(location.hash.substring(location.hash.indexOf('?'))) || {}
        const parsed = { ...parsedQuery, ...parsedSearch }
        const {
          userIsColorwebNoHotspot,
          colorwebUserType,
          userIsPress,
          multilook,
          watches,
          kids,
          tempaccess,
          quality,
          host,
          token,
          parentUrl,
          cosmogonie,
          user_auth_token // eslint-disable-line
        } = parsed

        this.$root.colorwebUserType = colorwebUserType || this.$root.colorwebUserType
        this.$root.userIsColorwebNoHotspot = userIsColorwebNoHotspot || this.$root.userIsColorwebNoHotspot

        this.$root.userIsPress = userIsPress || this.$root.userIsPress
        if (this.$root.userIsPress === 'false') {
          this.$root.userIsPress = false
        }
        this.$root.multilook = multilook || this.$root.multilook
        if (this.$root.multilook === 'false') {
          this.$root.multilook = false
        }
        this.$root.watches = watches || this.$root.watches
        if (this.$root.watches === 'false') {
          this.$root.watches = false
        }

        this.$root.kids = kids || this.$root.kids
        if (this.$root.kids === 'false') {
          this.$root.kids = false
        }

        this.$root.quality = quality || this.$root.quality
        this.$root.user_auth_token = user_auth_token || this.$root.user_auth_token // eslint-disable-line
        this.$root.host = host || this.$root.host

        this.$root.ColorWebToken = token || this.$root.ColorWebToken

        if (tempaccess && tempaccess === '22052021219') {
          this.$root.ColorWebToken = 'd4fa5687dc8a287f27d8a96b8a21dffd42a542ca35ed47315fd7bed289f51c9b'
          this.$root.kids = true
        }
        this.$root.parentUrl = parentUrl || this.$root.parentUrl

        if (host && host === 'colorweb') {
          this.$root.twinsburg = true
          console.log('COLORWEB TWINSBURG')
        }

        if (host && host === 'brandexperience') {
          this.$root.cosmogonie = cosmogonie || this.$root.cosmogonie
          if (this.$root.cosmogonie) {
            console.log('BRAND EXPERIENCE COSMOGONIE')
          } else {
            this.$root.twinsburg = true
            console.log('BRAND EXPERIENCE TWINSBURG')
          }
        }
      },
      updateCookie () {
        if (this.$root.host === 'brandexperience') {
          const cookieRooms = []
          for (let i = 0; i < this.$root.visitedRooms.length; i++) {
            const room = this.$root.visitedRooms[i]
            cookieRooms.push(room)
          }
          console.log('all cookies product to save', cookieRooms)
          console.log(JSON.stringify(cookieRooms))
          let vtcookie = [
            'gucciVirtualTourRooms',
            '=',
            JSON.stringify(cookieRooms)
          ].join('')
          vtcookie += '; SameSite=None; Secure'
          document.cookie = vtcookie

          const msgEvent = {
            event: 'setVTCookie',
            cookie: vtcookie
          }
          console.log('Brand Experience POST MESSAGE', msgEvent)
          window.parent.postMessage(msgEvent, '*')
        }
      },

      checkRoute () {
        if (this.$route &&
          this.$route.query &&
          this.$route.query.parentUrl) {
          this.$root.parentUrl = this.$route.query.parentUrl
          if (this.$root.parentUrl &&
            this.$root.parentUrl.indexOf('brandexperienceloc') > -1) {
            console.log('use loc')
            this.$root.getImageProxyURL = 'https://virtualtour-getimage-proxy-loc.wwmis.gucci/'
          }
        }
      },
      async callColorweb (STMACO) {
        if (this.cacheColorwebAPI[STMACO]) {
          return this.cacheColorwebAPI[STMACO]
        }
        const params = "[{'TOKEN' :'" + this.$root.ColorWebToken + "' , 'STMACO':'" + STMACO + "', 'OP':'N'}]"

        try {
          const result = await axios.get('https://showroomtool.kering.net/ADAM-API-GUCCIS/api/wishlist?Key=' + params)
          this.cacheColorwebAPI[STMACO] = result
          return result
        } catch (err) {
          return null
        }
      },
      async getImageShowroom (imageLinkS3, imageLink, isBackup = false, customPath = 'showroom') {
        // console.log(window.Modernizr)

        if (this.cacheImage[imageLink]) {
          return this.cacheImage[imageLink]
        }

        let imageURL = this.getImageProxyURL + 'VirtualTour/' + customPath + '/' + imageLink

        if (isBackup) {
          imageURL = this.$root.getImageProxyURL + 's3' + imageLinkS3
        }

        const getImageHeader = {}

        if (this.host && this.host === 'colorweb') {
          const cwToken = this.$root.ColorWebToken ? this.$root.ColorWebToken : ''
          getImageHeader.Source = 'CW'
          getImageHeader.Authorization = cwToken
        } else if (this.host && this.host === 'brandexperience') {
          getImageHeader.Source = 'BE'
          const authToken = this.$root.user_auth_token ? this.$root.user_auth_token : ''
          getImageHeader.Authorization = 'Bearer ' + authToken
          // getImageHeader.Source = 'CW'
          // getImageHeader.Authorization = 'd4fa5687dc8a287f27d8a96b8a21dffd42a542ca35ed47315fd7bed289f51c9b'
        }
        // Authorization: 'd4fa5687dc8a287f27d8a96b8a21dffd42a542ca35ed47315fd7bed289f51c9b'
        try {
          const image = await axios.get(imageURL, {
            responseType: 'blob',
            headers: getImageHeader
          })
          this.cacheImage[imageLink] = URL.createObjectURL(image.data) //eslint-disable-line
          // console.log(this.cacheImage[imageLink])
          return this.cacheImage[imageLink]
        } catch (err) {
          if (imageURL.indexOf('backup') > -1) {
            return ''
          }
          return this.getImageShowroom(imageLinkS3, imageLink, true)
        }
      },

      async getImage (imageLink, isGetImage, photoLook) {
        /* IMAGE PROXY */
        if (this.cacheImage[imageLink]) {
          return this.cacheImage[imageLink]
        }

        if (this.$root.host &&
          this.$root.host === 'brandexperience') {
          this.checkRoute()
        }

        let imageURL = this.$root.getImageProxyURL + 's3' + imageLink

        if (isGetImage) {
          if (photoLook) {
            imageURL = this.$root.getImageProxyURL + 'getimage/?Cod=' + imageLink
          } else {
            imageURL = this.$root.getImageProxyURL + 'getimage/?Cod=' + imageLink + '&Dim=L&Ord=S'
          }
        }

        const getImageHeader = {}

        if (this.host && this.host === 'colorweb') {
          const cwToken = this.$root.ColorWebToken ? this.$root.ColorWebToken : ''
          getImageHeader.Source = 'CW'
          getImageHeader.Authorization = cwToken
        } else if (this.host && this.host === 'brandexperience') {
          // getImageHeader.Source = 'BE'
          // const authToken = this.$root.user_auth_token ? this.$root.user_auth_token : ''
          // getImageHeader.Authorization = 'Bearer ' + authToken
          getImageHeader.Source = 'CW'
          getImageHeader.Authorization = 'd4fa5687dc8a287f27d8a96b8a21dffd42a542ca35ed47315fd7bed289f51c9b'
        } else {
          // todo?
        }

        try {
          const image = await axios.get(imageURL,
            {
              responseType: 'blob',
              headers: getImageHeader
            })
          this.cacheImage[imageLink] = URL.createObjectURL(image.data) //eslint-disable-line
          // console.log(this.cacheImage[imageLink])
          return this.cacheImage[imageLink]
        } catch (err) {
          return ''
        }

        /* IMAGE PROXY */
      },

      async getImageItem (imageLink) {
        /* IMAGE PROXY */
        if (this.cacheImage[imageLink]) {
          return this.cacheImage[imageLink]
        }

        if (this.$root.host &&
          this.$root.host === 'brandexperience') {
          this.checkRoute()
        }

        const imageURL = this.$root.getImageProxyURL + 'getimage/?Cod=' + imageLink + '&Dim=L&Ord=S&Transp=1'

        const getImageHeader = {}

        if (this.host && this.host === 'colorweb') {
          const cwToken = this.$root.ColorWebToken ? this.$root.ColorWebToken : ''
          getImageHeader.Source = 'CW'
          getImageHeader.Authorization = cwToken
        } else if (this.host && this.host === 'brandexperience') {
          // getImageHeader.Source = 'BE'
          // const authToken = this.$root.user_auth_token ? this.$root.user_auth_token : ''
          // getImageHeader.Authorization = 'Bearer ' + authToken
          getImageHeader.Source = 'CW'
          getImageHeader.Authorization = 'd4fa5687dc8a287f27d8a96b8a21dffd42a542ca35ed47315fd7bed289f51c9b'
        } else {
          // todo?
        }

        try {
          const image = await axios.get(imageURL,
            {
              responseType: 'blob',
              headers: getImageHeader
            })

          this.cacheImage[imageLink] = URL.createObjectURL(image.data) //eslint-disable-line
          // console.log(this.cacheImage[imageLink])
          return this.cacheImage[imageLink]
        } catch (err) {
          return ''
        }

        /* IMAGE PROXY */
      }
    },

    computed: {
      platform () {
        return Platform
      },
      multilanguage () {
        return packageConfig.multilanguage
      }
    },
    // this block allows passing props to the main App
    // these props are set by html-webpack-plugin in the html
    // and the values are taken from git-revision-webpack-plugin
    render (createElement) {
      return createElement(App, {
        props: {
          preloader: preloaderInstance
        }
      })
    }
  })
}
