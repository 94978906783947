var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-gallery",class:[
    {'full': !_vm.mainPhotoExist},
    {'eyewear': _vm.$root.eyewear},
    {'exquisite': _vm.$root.exquisite},
    {'cosmogonie': _vm.$root.cosmogonie},
    {'kids': _vm.$root.kids}
  ],style:(_vm.getStyle)},[(_vm.isMultiple)?_c('div',{staticClass:"total-set",class:{'center': !_vm.mainPhotoExist}},_vm._l((_vm.totalSet),function(index){return _c('div',{key:'set' + index,staticClass:"product-set",class:{'active': index - 1 === _vm.currentSetId},on:{"click":function($event){return _vm.$emit('updateSet', index - 1)}}})}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"gallery-wrapper",style:({transform: 'translateX(' + _vm.galleryX + 'px)' })},_vm._l((_vm.content),function(item,id){return _c('div',{key:'item'+id,ref:'item'+id,refInFor:true,staticClass:"item mr-6 mr-md-12 mb-2"},[_c('transition',{attrs:{"name":"fade-slide","appear":""}},[_c('div',{staticClass:"item-content",style:({transitionDelay: ((id + 1) * 0.1) + 's'})},[((item.STMACO && _vm.$root.host === 'brandexperience' && _vm.beCodeExist(item.STMACO)) ||
              (item.STMACO && _vm.$root.host === 'colorweb' && _vm.active[id] === true) ||
              _vm.isLocalhost)?_c('div',{staticClass:"content pa-6 "},[_c('div',{staticClass:"img",style:({'background-image': 'url('+_vm.images[id]+')'})}),_vm._v(" "),(_vm.$root.host === 'colorweb' || (!_vm.$root.cartIsLocked && !_vm.$root.userIsPress && _vm.$root.host === 'brandexperience' && _vm.beCodeExist(item.STMACO)))?_c('div',{staticClass:"button add-remove ",class:[
                {'pointer-none': _vm.disableButton},
                {'brandexperience': _vm.$root.host === 'brandexperience'},
                {'is-press': _vm.$root.host === 'brandexperience' && _vm.$root.userIsPress === true}
              ]},[(!_vm.checkWishlist(item.STMACO))?_c('div',{staticClass:"button-wishlist",on:{"click":function($event){return _vm.addItem(item.STMACO)}}},[(_vm.$root.host === 'colorweb')?_c('span',{staticClass:"mr-5 uppercase"},[_vm._v(_vm._s(_vm.$root.language[_vm.$route.params.language].addToWishlist))]):_c('span',{staticClass:"mr-5 uppercase"},[_vm._v(_vm._s(_vm.$root.language[_vm.$route.params.language].addToBag))]),_vm._v(" "),_c('div',{staticClass:"circle"},[(_vm.$root.host === 'colorweb')?_c('iconBag',{staticClass:"bag",class:_vm.$root.kids ? 'svg-stroke-cream': 'svg-stroke-white'}):_c('iconCart',{class:_vm.$root.kids ? 'svg-stroke-cream': 'svg-stroke-white'})],1)]):_vm._e(),_vm._v(" "),(_vm.checkWishlist(item.STMACO))?_c('div',{staticClass:"button-wishlist remove",on:{"click":function($event){return _vm.removeItem(item.STMACO)}}},[_c('span',{staticClass:"mr-5 uppercase"},[_vm._v(_vm._s(_vm.$root.language[_vm.$route.params.language].remove))]),_c('iconBagRemove')],1):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.description[id])?_c('p',{staticClass:"mt-3 description"},[_vm._v(_vm._s(_vm.description[id]))]):_vm._e(),_vm._v(" "),(_vm.$root.host === 'colorweb' || (_vm.$root.host === 'brandexperience' && _vm.beCodeExist(item.STMACO)))?_c('div',{staticClass:"mt-6",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.onItemClick(item.link, item.STMACO)}}},[_c('div',{staticClass:"button-black-padding uppercase ",class:[
                  {'brandexperience': _vm.$root.host === 'brandexperience'}
                ]},[_vm._v(_vm._s(_vm.$root.language[_vm.$route.params.language].seeDetails))])]):_vm._e()]):_vm._e()])])],1)}),0),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:((!_vm.smallView || _vm.isMultiple)),expression:"(!smallView || isMultiple)"}],staticClass:"nav mt-1 mt-md-3",class:[
      {'brandexperience': _vm.$root.host === 'brandexperience'},
      {'center': !_vm.mainPhotoExist}
    ]},[_c('div',{staticClass:"arrow mr-6",on:{"click":function($event){return _vm.updateImage(-1)}}},[_c('IconArrowLeft',{staticClass:"absolute-center svg-stroke-white"})],1),_vm._v(" "),_c('div',{staticClass:"arrow",on:{"click":function($event){return _vm.updateImage(1)}}},[_c('IconArrowRight',{staticClass:"absolute-center svg-stroke-white"})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }