<template>
  <transition name="tutorial">
    <div v-if="show" class="tutorial fixed-100vwvh"
      :class="[
      {'cosmogonie': $root.cosmogonie},
      {'exquisite': $root.exquisite},
      {'eyewear': $root.eyewear}
    ]">

      <div class="tutorial-content">
        <v-row justify="center" class="tutorial-title mb-6 mb-md-12">
          <h3 class="text-outline bold">{{$root.language[$route.params.language].tutorialTitle}}</h3>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col
            v-if="!$root.platform.mobile || currentStep === 0"
            :cols="$root.platform.mobile ? 12 : 4"
            class="step-1 step pa-md-12">
            <tutorial1 class="mb-10"></tutorial1>
            <h5 class="mb-3">1.</h5>
            <p>{{$root.language[$route.params.language].tutorialClickDrag}}</p>
          </v-col>
          <v-col
            v-if="!$root.platform.mobile || currentStep === 1"
            :cols="$root.platform.mobile ? 12 : 4"
            class="step-2 step pa-md-12"
            :class="{'border-x': !$root.platform.mobile}">
            <tutorial2 class="mb-12"></tutorial2>
            <h5 class="mb-3">2.</h5>
            <p>{{$root.language[$route.params.language].tutorialDiscover}}</p>
          </v-col>
          <v-col
            v-if="!$root.platform.mobile || currentStep === 2"
            :cols="$root.platform.mobile ? 12 : 4"
            class="step-3 step pa-md-12">
            <tutorial3wishlist v-if="$root.host === 'colorweb'" class="mb-10"></tutorial3wishlist>
            <tutorial3 v-else class="mb-11"></tutorial3>
            <h5 class="mb-3">3.</h5>
            <p>{{$root.host === 'colorweb' ? $root.language[$route.params.language].tutorialWishlist : $root.language[$route.params.language].tutorialFavorite}}</p>
          </v-col>
        </v-row>

        <v-row class="explore" justify="center">
          <div @click="show = false" class="button-black-padding uppercase">{{$root.language[$route.params.language].explore}}</div>
        </v-row>
      </div>
      <div class="nav" v-if="$root.platform.mobile" justify="center">
        <div v-if="currentStep > 0" @click="updateTutorial(-1)" class="arrow left">
          <IconArrowLeft class="absolute-center svg-fill-black"></IconArrowLeft>
        </div>
        <div v-if="currentStep < 2" @click="updateTutorial(1)" class="arrow right">
          <IconArrowRight class="absolute-center svg-fill-black"></IconArrowRight>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import tutorial1 from '@/assets/svg/icon-tutorial-1.svg'
import tutorial2 from '@/assets/svg/icon-tutorial-2.svg'
import tutorial3 from '@/assets/svg/icon-tutorial-3.svg'
import tutorial3wishlist from '@/assets/svg/icon-tutorial-3-wishlist.svg'
import IconArrowLeft from '@/assets/svg/arrow-left.svg'
import IconArrowRight from '@/assets/svg/arrow-right.svg'

export default {
  name: 'Tutorial',

  components: {
    tutorial1, tutorial2, tutorial3, tutorial3wishlist, IconArrowLeft, IconArrowRight
  },
  data () {
    return {
      show: false,
      currentStep: 0
    }
  },

  methods: {
    updateTutorial (index) {
      let nstep = this.currentStep + index
      if (nstep < 0) {
        nstep = 0
      }

      if (nstep > 2) {
        nstep = 2
      }
      this.currentStep = nstep
    }
  },
  mounted () {
    this.alreadySeen = false

    this.$events.on('isLoadingEnd', () => {
      if (this.$route.name === 'Room' && !this.alreadySeen && !this.$root.deeplink) {
        this.show = true
        this.alreadySeen = true
      }
    })
  }
}
</script>

<style lang="scss" scoped>

.cosmogonie .tutorial {
  background-color: rgba(18, 23, 43, 0.9);
}

.tutorial {
  background-color: $c-black-90;
  z-index: 3;
  text-align: center;
}

.tutorial-content {
  width: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include breakpoint('xs-only') {
    width: 75%;
  }
}

.eyewear {
  h3 {
    color: $c-white;
  }

  .border-x {
    border-left: 1px solid $c-white;
    border-right: 1px solid $c-white;
  }

  svg {
    height: 140px;
    align-self: center;

    path {
      stroke: $c-white !important;
      fill: transparent;
    }
  }
}

.step {
  svg {
    overflow: visible;
    height: 140px;
    align-self: center;
  }

  h5 {
    text-decoration: underline;
  }

  &.step-3 {
    path {
      stroke: $c-gold;
      fill: transparent;
    }
  }
}

h3 {
  color: $c-white;
  text-shadow: none;
}

.cosmogonie {
  .border-x {
    border-left: 1px solid $c-cream;
    border-right: 1px solid $c-cream;
  }

  .step-1 {
    path {
      fill: $c-cream;
      stroke: $c-cream;
    }
  }

  .step-2 {
    path {
      fill: $c-cream;
      stroke: $c-cream;
    }

    circle {
      stroke: $c-cream;
    }
  }

  .step-3 {
    path {
      stroke: $c-cream;
      fill: transparent;
    }
  }
}

.exquisite {
  .border-x {
    border-left: 1px solid $c-white;
    border-right: 1px solid $c-white;
  }

  .step-1 {
    path {
      fill: $c-yellow;
      stroke: $c-yellow;
    }
  }

  .step-2 {
    path {
      fill: $c-tosca;
      stroke: $c-tosca;
    }

    circle {
      stroke: $c-tosca;
    }
  }

  .step-3 {
    path {
      stroke: $c-red-exq;
      fill: transparent;
    }
  }
}

.nav {
  position: fixed;
  width: 100%;
  top: 50%;
  transform: translateY(50px);
}

.arrow {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  pointer-events: all;

  &.left {
    float: left;
  }

  &.right {
    float: right;
  }
}

.border-x {
  border-left: 1px solid $c-red;
  border-right: 1px solid $c-red;
}

.tutorial-leave-active {
  transition: opacity 1s;

  .tutorial-title,
  .step-1,
  .step-2,
  .step-3,
  .explore {
    transition: opacity 0.5s, transform 1s $ease-out-quint;
  }

  .step-1 { transition-delay: 0.2s; }
  .step-2 { transition-delay: 0.3s; }
  .step-3 { transition-delay: 0.4s; }
  .explore { transition-delay: 0.5s; }
}

.tutorial-enter-active {
  transition: opacity 2s;

  .tutorial-title,
  .step-1,
  .step-2,
  .step-3,
  .explore {
    transition: opacity 1s, transform 1s $ease-out-quint;
  }

  .step-1 { transition-delay: 0.5s; }
  .step-2 { transition-delay: 0.7s; }
  .step-3 { transition-delay: 0.9s; }
  .explore { transition-delay: 1s; }
}

.tutorial-enter,
.tutorial-leave-to {
  .tutorial-title,
  .step-1,
  .step-2,
  .step-3,
  .explore {
    opacity: 0 !important;
    transform: translateY(10px) !important;
  }
}
</style>
