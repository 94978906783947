<template>
  <div class="room-panorama" v-if="currentPanorama" :class="{'absolute': isHotspotOpen}">

      <div class="pano-container" ref="pano" :style="{transform: 'translateX(' + targetX + 'px)' }">
        <div class="horizontal-wrapper" @click="getPosition">
          <div
            v-for="(pano, index) in currentPanorama.panorama"
            :key="'pano' + index"
            class="pano-wrapper">
            <img class="pano-img" :src="panoramas[index]" @load="onImageLoaded" draggable="false">
          </div>

          <HotspotHtml v-for="(hs, index) in roomHotspotContent"
            :hotspot="hs"
            :key="'hs'+index"
            :style="{left: parsePosition(hs.position.position).x + '%', top: parsePosition(hs.position.position).y + '%' }"
            ></HotspotHtml>

          <div class="hotspot-room" v-for="(hotspot, index) in currentPanorama.hotspotRoom"
            :key="'hr'+index"
            :ref="'hotspot-room'+index"
            :style="{left: parsePosition(hotspot.position).x + '%', top: parsePosition(hotspot.position).y + '%' }"
          >
            <HotspotRoomHtml
              v-if="!hotspot.room.colorwebRetailOnly || (hotspot.room.colorwebRetailOnly && $root.userIsColorwebRetail)"
              :hotspot="hotspot"/>
          </div>

        </div>
      </div>

      <div v-if="!$root.platform.mobile && !$root.platform.ios" class="scroller" :style="{height: scrollHeight + 'px'}"></div>
  </div>
</template>

<script>
import HotspotRoomHtml from '../atoms/HotspotRoomHtml.vue'
import HotspotHtml from '../atoms/HotspotHtml.vue'
import GetRoom from '@/graphql/GetRoom.gql'
import { Viewport } from '@monogrid/vue-lib'

export default {
  name: 'Panorama',
  mixins: [Viewport],

  data () {
    return {
      currentPanorama: null,
      scrollHeight: 0,
      isHotspotOpen: false,
      allImageLoaded: false,
      targetX: 0,
      panoramas: [],
      roomHotspotContent: []
    }
  },

  components: {
    HotspotHtml, HotspotRoomHtml
  },

  mounted () {
    this.dragging = false
    this.dragX = 0
    this.checkRoute()
  },

  methods: {
    addListener () {
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('mousedown', this.onMouseDown)
      window.addEventListener('mousemove', this.onMouseMove)
      window.addEventListener('mouseup', this.onMouseUp)
    },

    removeListener () {
      window.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('mousedown', this.onMouseDown)
      window.removeEventListener('mousemove', this.onMouseMove)
      window.removeEventListener('mouseup', this.onMouseUp)
    },

    onMouseDown (e) {
      this.dragging = true
      this.dragX = e.clientX
      // console.log('mousedown')
    },
    onMouseUp () {
      this.dragging = false
      // console.log('mouseup')
    },
    onMouseMove (e) {
      // console.log(this.dragging)
      if (this.dragging) {
        // console.log(this.dragX - e.clientX)
        window.scrollBy(0, this.dragX - e.clientX)
        this.dragX = e.clientX
      }
    },
    getPosition (e) {
      console.log(e)
      if (this.$refs.pano) {
        const px = (((e.clientX - this.targetX) / this.$refs.pano.clientWidth) * 100).toFixed(2)
        const py = ((e.clientY / this.$refs.pano.clientHeight) * 100).toFixed(2)
        console.log(px.toString() + ',' + py.toString())
      }
    },

    parsePosition (pos) {
      return { x: pos.split(',')[0], y: pos.split(',')[1] }
    },

    handleScroll (event) {
      if (this.currentPanorama && this.$refs.pano) {
        // console.log(event, window.scrollY, this.scrollHeight)
        const perc = window.scrollY / (this.scrollHeight - this.viewPort.height)
        this.targetX = (this.$refs.pano.clientWidth - this.viewPort.width) * perc * -1
        // console.log(this.targetX)
      }
    },
    async preloadImage () {
      for (let i = 0; i < this.currentPanorama.panorama.length; i++) {
        const pano = this.currentPanorama.panorama[i]
        const imagepano = await this.$root.getImage('/media/' + pano.id, false)
        this.$set(this.panoramas, i, imagepano)
        this.onImageLoaded()
      }
    },
    onImageLoaded () {
      console.log('image loaded')
      this.imageLoaded++
      this.updateScrollHeight()

      if (this.imageLoaded === this.currentPanorama.panorama.length) {
        setTimeout(() => {
          this.allImageLoaded = true
          this.$events.emit('isLoadingEnd')
        }, 500)

        if (!this.$root.visitedRooms.includes(this.$route.params.roomName)) {
          this.$root.visitedRooms.push(this.$route.params.roomName)
          this.$root.updateCookie()
        }
      }
    },

    updateScrollHeight () {
      this.scrollHeight = this.viewPort.height
      if (this.$refs.pano) {
        const perc = this.$refs.pano.clientWidth / this.viewPort.width
        this.scrollHeight = Math.floor(perc * this.viewPort.height)
      }
    },

    checkRoute () {
      if (this.$route.params.hotspotContent) {
        if (!this.$root.platform.mobile && !this.$root.platform.ios) {
          this.isHotspotOpen = true
          this.lastY = window.scrollY
          this.removeListener()
        }
      } else {
        this.isHotspotOpen = false
        if (this.currentPanorama) {
          if (!this.$root.platform.mobile && !this.$root.platform.ios) {
            console.log('gotolastY')
            if (this.lastY) {
              setTimeout(() => {
                window.scrollTo(0, this.lastY)
              }, 1)
            }
            this.addListener()
          }
        }
      }
    }
  },

  watch: {
    currentPanorama () {
      if (this.currentPanorama && !this.$root.platform.mobile && !this.$root.platform.ios) {
        this.$events.emit('showScroll')
      } else {
        this.$events.emit('hideScroll')
      }
    },
    'viewPort.height': function (value) {
      this.updateScrollHeight()
    },
    '$apollo.loading': function (value) {
      if (value) {
        this.$events.emit('isLoadingStart')
      }
    },
    $route: function (value) {
      this.checkRoute()
    }
  },

  apollo: {
    Room: {
      query: GetRoom,
      skip () {
        return !this.$route.params.roomName
      },
      variables () {
        return {
          slug: this.$route.params.roomName
        }
      },
      async result () {
        // console.log('Get Room result:', this.Room)
        if (this.Room && this.Room.panorama && this.Room.panorama.length > 0) {
          if (!this.Room.colorwebRetailOnly || (this.Room.colorwebRetailOnly && this.$root.userIsColorwebRetail)) {
            if (!this.currentPanorama || this.Room.roomTitle !== this.currentPanorama.roomTitle) {
              this.$events.emit('isLoadingStart')
              this.allImageLoaded = false
              this.imageLoaded = 0
              this.currentPanorama = this.Room
              this.panoramas = []
              this.preloadImage()
              this.dragX = 0
              this.targetX = 0
              this.roomHotspotContent = this.currentPanorama.hotspotContentReference

              if (!this.$root.platform.mobile && !this.$root.platform.ios) {
                this.addListener()
              }

              if (window.firebase.analytics() && this.$root.host) {
                window.firebase.analytics().logEvent('Enter_room', {
                  roomName: this.Room.roomTitle,
                  host: this.$root.host
                })
                console.log('Firebase logEvent', window.firebase.analytics())
              }
            }
          } else {
            this.$router.push(
              {
                name: 'Room',
                params: {
                  roomName: this.$root.allSettings.landingRoom._slug
                }
              }
            )
          }
        } else {
          this.removeListener()
          this.currentPanorama = null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ios,
.mobile {
  .room-panorama {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .pano-container {
    position: relative;
  }

}

.room-panorama {
  overflow-x: hidden;
  overflow-y: scroll;
  user-select: none;

  &.absolute {
    position: absolute;
  }
}

.scroller {
  width: 100vw;
  pointer-events: none;
  display: block;
}

.pano-container {
  white-space: nowrap;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  transition: transform 0.5s;
}

.horizontal-wrapper {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  width: fit-content;
  position: relative;
}

.pano-wrapper {
  position: relative;
  display: inline-block;
}

.hotspot-room {
  position: absolute;
  pointer-events: auto;
}

.pano-img {
  height: calc(100vh - 80px);
  width: auto;
  user-select: none;
}
</style>
