<template>
  <div class="rotate-device" v-if="$root.platform.mobile && $root.platform.phone && show">
    <div class="content">
      <v-container fluid fill-height>
        <v-row class="height-100 pa-0"
          align="center"
          justify="center">
          <v-col align="center">
            <IconRotate></IconRotate>
            <h3 class="mt-8 c-black bodoni uppercase">{{$root.language[$route.params.language].rotateDevice}}</h3>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { Viewport } from '@monogrid/vue-lib'
import IconRotate from '@/assets/svg/rotate.svg'

export default {
  name: 'RotateDevice',
  mixins: [Viewport],

  components: {
    IconRotate
  },

  data () {
    return {
      show: false
    }
  },

  watch: {
    viewPort (newValue, oldValue) {
      if (newValue.width > newValue.height) {
        this.show = true
      } else {
        this.show = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rotate-device {
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 10;
  background-color: $c-anthracite;
  padding: 5vh 12vw;
  text-align: center;

  svg {
    width: 48px;
    height: 48px;
  }
}

.content {
  background-color: $c-white;
  width: 100%;
  height: 100%;
}
</style>
