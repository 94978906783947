<template>
  <v-app
    id="app"
    class="app"
    v-if="isReady && allLoaded && isBECodeLoaded"
    v-show="!platform.prerenderer"
    :class="[
      {'cosmogonie' : $root.cosmogonie},
      {'twinsburg': $root.twinsburg},
      {'kids' : $root.kids},
      {'exquisite' : $root.exquisite},
      {'eyewear' : $root.eyewear},
      {'scroll' : isScroll},
      {'portrait' : viewPort.width < viewPort.height}
    ]">

    <!-- <TwinsburgMenu v-if="$root.twinsburg" /> -->

    <transition name="fade">
      <div v-if="$root.host && isPano && !$root.twinsburg">
        <Panolens360 ref="pano" />
        <Panorama v-if="$root.host && !$root.twinsburg" ref="panorama" />
        <Footer />
        <Tutorial />
      </div>
    </transition>

    <transition name="fade">
      <router-view />
    </transition>

    <transition name="scale">
      <HotSpotOverlay v-if="showHotspotContent && !$root.twinsburg"/>
    </transition>

    <!--<div class="header">
      <logoGucci class="svg-fill-white"></logoGucci>
    </div>-->
    <!-- Loading -->
    <transition name="fade">
      <div v-if="isLoading" class="preloader fixed-100vwvh"
        :class="[
          {'twinsburg': $root.twinsburg},
          {'cosmogonie': $root.cosmogonie},
          {'kids': $root.kids},
          {'exquisite': $root.exquisite},
          {'eyewear': $root.eyewear}
        ]">
        <div class="content absolute-center">
          <!--<iconExquisite v-if="$root.exquisite" class="welcome"></iconExquisite>-->
          <iconTwinsburg v-if="$root.twinsburg" class="welcome"></iconTwinsburg>
          <iconLoadingKids v-if="$root.cosmogonie" class="welcome"></iconLoadingKids>
          <iconExquisite v-else-if="$root.exquisite" class="welcome"></iconExquisite>

          <p class="text-outline uppercase">LOADING</p>
        </div>
      </div>
    </transition>

    <!--<BannerCookie v-if="isReady && allLoaded"></BannerCookie>-->

    <RotateDevice></RotateDevice>
  </v-app>
</template>

<script>
import SiteSetting from '@/graphql/GetSiteSetting.gql'
import ListTranslation from '@/graphql/ListTranslation.gql'
// import iconExquisite from '@/assets/svg/logo-exquisite.svg'
import iconTwinsburg from '@/assets/svg/logo-twinsburg.svg'

import iconExquisite from '@/assets/svg/logo-exquisite.svg'
import iconLoadingKids from '@/assets/svg/logo-kids-fw22.svg'

import Footer from './organisms/Footer.vue'
import Tutorial from './organisms/Tutorial.vue'
import Panorama from './molecules/Panorama.vue'
import RotateDevice from './organisms/RotateDevice.vue'
// import BannerCookie from './atoms/BannerCookie.vue'
// import { VersionInfo } from '@monogrid/vue-lib'
import { Platform } from '@monogrid/js-utils'
import { Viewport } from '@monogrid/vue-lib'

import shareImage from '@/assets/img/share.jpg'
import Panolens360 from '@/molecules/Panolens360'
import axios from 'axios'
/* Preloader gif */
import preloaderGif from '@/assets/img/preloader.gif'

/* Hotspot */
import HotSpotOverlay from '@/molecules/HotspotOverlayContent.vue'
// import logoGucci from '@/assets/svg/logo.svg'
// import setting from '@/assets/data/setting.js'
// import content from '@/assets/data/content.js'

// import TwinsburgMenu from './organisms/TwinsburgMenu.vue'

let initializedModernizr = false

export default {
  mixins: [Viewport],

  name: 'App',

  components: {
    Panolens360,
    // VersionInfo,
    // logoGucci
    HotSpotOverlay,
    // BannerCookie,
    iconExquisite,
    iconLoadingKids,
    iconTwinsburg,
    RotateDevice,
    Panorama,
    Tutorial,
    Footer
    // TwinsburgMenu
  },

  data: function () {
    return {
      /* Preloader */
      preloaderGif,
      isScroll: false,
      isLoading: false,
      isBECodeLoaded: false,
      // this flag is used to show the app once the preloading
      // (if present) is finished
      isReady: false,
      isAllSiteSettingsLoaded: false,
      isAllTranslationsLoaded: false,
      twinsburgMenuCategories: null,
      // platform, accessible with an import statement
      // or by using this.$root.platform
      // NOTE: import statement is preferred
      platform: Platform,
      showHotspotContent: false
    }
  },

  mounted: function () {
    // set this.isReady = true
    // when all stuff that needs to be loaded for the app is loaded
    // if you need to preload stuff, delete this line and set to true later

    // console.log(setting)
    // this.$root.setting = setting
    // this.$root.content = content
    /* Preloader */
    if (this.$route.params.roomName) {
      this.$root.deeplink = true
    }

    this.$events.on('isLoadingStart', () => { this.isLoading = true })
    this.$events.on('isLoadingEnd', () => { this.isLoading = false })

    this.$events.on('showScroll', () => { this.isScroll = true })
    this.$events.on('hideScroll', () => { this.isScroll = false })

    // console.log('MOUNTED', this.$route)
    this.checkRoute()
  },

  updated () {
    // if not prerenderer
    if (!Platform.prerenderer && window.Modernizr && !initializedModernizr) {
      // add custom modernizr tests
      initializedModernizr = true
      for (const key in Platform) {
        window.Modernizr.addTest(key, () => {
          return Platform[key]
        })
      }
    }
  },

  // Query
  apollo: {
    // Site settings
    SiteSetting: {
      query: SiteSetting,
      async result () {
        this.$root.allSettings = this.SiteSetting
        this.$root.useLOWRES = this.$root.allSettings.useLOWRES
        console.log('Settings loaded', this.$root.allSettings)
        this.isAllSiteSettingsLoaded = true
      }
    },
    // All translations
    ListTranslation: {
      query: ListTranslation,
      async result () {
        this.$root.language = {}
        for (let i = 0; i < this.ListTranslation.items.length; i++) {
          const element = this.ListTranslation.items[i]
          this.$root.language[element._slug] = element
        }
        // console.log('Translation loaded', this.$root.language)
        // console.log('DEBUG: CURRENT LANG TRANSLATIONS:', this.$root.language[this.$route.params.language])

        this.isAllTranslationsLoaded = true
      }
    }
  },

  methods: {
    checkRoute () {
      // host Brand Experience or Color Web
      this.$root.getQueryURL()

      if (this.$root.host && !this.isReady) {
        console.log('HOST PARENT', this.$root.host)
        if (this.$root.host === 'waf') {
          this.$root.getImageProxyURL = 'https://virtualtour-getimage-proxy-waf.wwmis.gucci/'
        }

        if (window.parent && this.$root.host === 'brandexperience') {
          this.initBrandExperience()
          // temporary solution, need to be called later
          this.isReady = true
          if (this.$root.parentUrl) {
            if (this.$root.parentUrl.indexOf('brandexperienceloc') > -1) {
              console.log('use loc')
              this.$root.getImageProxyURL = 'https://virtualtour-getimage-proxy-loc.wwmis.gucci/'
            }
          }
        } else if (window.parent && this.$root.host === 'colorweb') {
          this.initColorWeb()
          this.$root.twinsburg = true
          this.isBECodeLoaded = true
          // temporary solution, need to be called later
          this.isReady = true
        }
      } else {
        this.isReady = true
        this.isBECodeLoaded = true
      }

      // hotspot content
      if (this.$route.params.hotspotContent) {
        // console.log('Open hotspot', this.$route.params.hotspotContent)
        this.showHotspotContent = true
      } else {
        this.showHotspotContent = false
      }
    },

    async initColorWeb () {
      const params = "[{'TOKEN' :'" + this.$root.ColorWebToken + "' , 'STMACO':'', 'OP':'F'}]"
      const result = await axios.get('https://showroomtool.kering.net/ADAM-API-GUCCIS/api/wishlist?Key=' + params)
      console.log('init colorweb', result)
      if (result.data && result.data.length > 0) {
        this.$root.wishlist = result.data
      }

      let userType = ''
      if (this.$root.colorwebUserType) {
        userType = this.$root.colorwebUserType
      } else {
        const paramsUser = "[{'TOKEN' :'" + this.$root.ColorWebToken + "' , 'STMACO':'', 'OP':'U'}]"
        const resultUser = this.$root.colorwebUser || await axios.get('https://showroomtool.kering.net/ADAM-API-GUCCIS/api/wishlist?Key=' + paramsUser)
        console.log(resultUser)
        userType = (resultUser.data && resultUser.data.length > 0) ? resultUser.data[0].TYPEXX : false
      }

      if (userType === 'RET01') {
        console.log('USER IS COLORWEB RETAIL')
        this.$root.userIsColorwebRetail = true
      }

      if (userType === 'RET02') {
        console.log('USER IS COLORWEB RETAIL KIDS')
        this.$root.userIsColorwebRetail = true
        this.$root.kids = true
      }

      if (userType === 'WHL01') {
        this.$root.twinsburg = true
        console.log('USER IS COLORWEB WHOLESALE WITH HOTSPOT')
      }

      if (userType === 'WHL02') {
        console.log('USER IS COLORWEB WHOLESALE NO HOTSPOT')
        this.$root.userIsColorwebNoHotspot = true
      }

      if (userType === 'WHL03') {
        console.log('USER IS COLORWEB WATCHES')
        this.$root.watches = true
      }

      if (userType === 'WHL04') {
        console.log('USER IS COLORWEB KID NO HOTSPOT')
        this.$root.kids = true
        this.$root.userIsColorwebNoHotspot = true
        // this.$root.eyewear = true
      }

      if (userType === 'WHL06') {
        console.log('USER IS COLORWEB KID')
        // console.log('USER IS COLORWEB EYEWEAR NO HOTSPOT')
        this.$root.kids = true
        // this.$root.userIsColorwebNoHotspot = true
        // this.$root.eyewear = true
      }
    },

    initBrandExperience () {
      // window.addEventListener('getCurrentEvent', this.onBECurrentEvent, false)
      window.addEventListener('message', this.onBEMessage, false)

      const msg = { event: 'getEventCategories' }
      console.log('Brand Experience POST MESSAGE', msg)
      window.parent.postMessage(msg, '*')

      const msgEvent = { event: 'getEventArticles' }
      console.log('Brand Experience POST MESSAGE', msgEvent)
      window.parent.postMessage(msgEvent, '*')

      const msgCookie = {
        event: 'getVTCookie'
      }
      console.log('Brand Experience POST MESSAGE', msgEvent)
      window.parent.postMessage(msgCookie, '*')

      if (!this.$root.userIsPress) {
        const msgArt = { event: 'getCartArticles' }
        console.log('Brand Experience POST MESSAGE', msgArt.event, msgArt)
        window.parent.postMessage(msgArt, '*')
      }
    },

    onBEMessage (e) {
      if (window.parent && this.$root.host === 'brandexperience') {
        console.log('Brand Experience RECEIVE MESSAGE', e.data.event ? e.data.event : '', e)

        if (e.data.event === 'responseGetVTCookie' && e.data.cookie) {
          const gucciCookie = e.data.cookie.split('; ').find(row => row.startsWith('gucciVirtualTourRooms'))
          if (gucciCookie) {
            const cookiesRooms = JSON.parse(gucciCookie.split('=')[1])
            console.log('room from cookie', cookiesRooms)

            for (let i = 0; i < cookiesRooms.length; i++) {
              const room = cookiesRooms[i]
              if (room) {
                this.$root.visitedRooms.push(room)
              }
            }
          }
        }
        if (e.data.event === 'responseGetEventCategories') {
          this.$root.BECategory = []
          for (const prop in e.data) {
            if (prop !== 'event') {
              const cat = {
                name: e.data[prop].name,
                link: e.data[prop].id
              }
              this.$root.BECategory.push(cat)

              const msgArt = {
                event: 'getCategoryArticles',
                categoryId: cat.link
              }
              console.log('Brand Experience POST MESSAGE', msgArt.event, msgArt)
              window.parent.postMessage(msgArt, '*')
            }
          }
        }

        if (e.data.event === 'responseGetCartArticles') {
          this.$root.wishlist = e.data.articles
          this.$root.cartIsLocked = e.data.cart_is_locked
        }

        if (e.data.event === 'responseGetEventArticles') {
          console.log('BrandExperienceArticles')
          this.$root.BECode = e.data
          this.isBECodeLoaded = true
        }

        if (e.data.event === 'responseCategoryArticles') {
          for (const prop in e.data) {
            if (prop !== 'event') {
              const article = e.data[prop]
              this.$root.BEProducts.push(article)

              /*
              if (this.$root.BEProducts.length === 0) {
                this.$root.BEProducts.push(article)
              } else {
                for (let i = 0; i < this.$root.BEProducts.length; i++) {
                  const existingProduct = this.$root.BEProducts[i]
                  if (existingProduct.smc !== article.smc) {
                    this.$root.BEProducts.push(article)
                  }
                }
              } */
            }
          }
        }
      }
    }
  },

  computed: {
    allLoaded () {
      return this.isReady && this.isAllSiteSettingsLoaded && this.isAllTranslationsLoaded
    },
    isPano () {
      return this.$route.name !== 'Home'
    }
  },

  watch: {
    '$route' (value) {
      // console.log('ROUTE:', value)
      this.checkRoute()
    },
    isReady: function (value) {
      // hide / show preloader
      this.$root.preloader.visible = !value
    }
  },

  metaInfo: function () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Gucci Showroom',
      // all titles will be injected into this template
      titleTemplate: '%s Gucci Showroom',
      base: { href: '/' },
      meta: [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@monogridstudio' },
        { name: 'twitter:creator', content: '@monogridstudio' },
        // generic description
        { vmid: 'description', name: 'description', content: '360 tour for Gucci' },
        // og tags
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogurl', property: 'og:url', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + '/' + this.$route.fullPath },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'Gucci Showroom' },
        { vmid: 'ogdescription', property: 'og:description', content: '360 tour for Gucci' },
        { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + shareImage },
        { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
        { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
        { vmid: 'ogimagewidth', property: 'og:image:height', content: '630' }
      ]
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/general';

@font-face {
  font-family: 'Gucci Sans Pro';
  font-style: normal;
  font-weight: 100;
  src:
    url('assets/fonts/GucciSansPro-Light.woff2') format('woff2'),
    url('assets/fonts/GucciSansPro-Light.woff') format('woff');
}

@font-face {
  font-family: 'Gucci Sans Pro';
  font-style: normal;
  font-weight: 500;
  src:
    url('assets/fonts/GucciSansPro-Medium.woff2') format('woff2'),
    url('assets/fonts/GucciSansPro-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gucci Sans Pro';
  font-style: bold;
  font-weight: 600;
  src:
    url('assets/fonts/GucciSansPro-Bold.woff2') format('woff2'),
    url('assets/fonts/GucciSansPro-Bold.woff') format('woff');
}

html {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  font-size: 14px;
  scrollbar-width: thin;

  &.mobile {
    overflow-y: hidden !important;
  }
}

body {
  .theme--light.v-application {
    color: $c-white;
    font-family: 'Gucci Sans Pro';
    letter-spacing: normal;
    line-height: normal;
    font-weight: 100;
    overflow-y: hidden;
  }
}

.app {
  background-color: $c-white;
  overflow: hidden;
  height: auto !important;

  &.scroll {
    overflow-y: scroll !important;
  }
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #333;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

a {
  text-decoration: none;
  color: $c-black;
  cursor: pointer;
  pointer-events: all;
}

p {
  font-size: 16px;
  line-height: 1.67;

  @include breakpoint('md-and-down') {
    font-size: 14px;
    line-height: 1.5;
  }
}

h1 {
  font-size: 72px;

  @include breakpoint('lg-and-down') {
    font-size: 50px;
    line-height: 0.9;
  }

  @include breakpoint('md-and-down') {
    font-size: 36px;
    line-height: 0.9;
  }

  @include breakpoint('sm-and-down') {
    font-size: 36px;
  }
}

h2 {
  font-size: 60px;

  @include breakpoint('md-and-down') {
    font-size: 48px;
  }

  @include breakpoint('sm-and-down') {
    font-size: 36px;
  }

  @include breakpoint('xs-only') {
    font-size: 28px;
  }
}

h3 {
  font-size: 36px;

  @include breakpoint('md-and-down') {
    font-size: 28px;
  }

  @include breakpoint('sm-and-down') {
    font-size: 24px;
  }

  @include breakpoint('xs-only') {
    font-size: 24px;
  }

  &.light {
    font-weight: 100;
  }
}

h4 {
  font-size: 24px;
  letter-spacing: normal;

  @include breakpoint('md-and-down') {
    font-size: 22px;
  }

  @include breakpoint('sm-and-down') {
    font-size: 20px;
  }
}

h5 {
  font-size: 16px;

  @include breakpoint('md-and-down') {
    font-size: 14px;
  }
}

h6 {
  font-size: 14px;
}

span {
  font-size: 12px;
}

.iphone .iphone-hide {
  display: none;
}

.ipad .iphone-hide {
  display: none;
}

.text-outline {
  color: $c-red;
  text-shadow: -1px 0 $c-gold, 0 1px $c-gold, 1px 0 $c-gold, 0 -1px $c-gold;
}

.button-black-padding {
  background-color: $c-black;
  border: 3px solid $c-gold;
  color: $c-white;
  display: inline-block;
  padding: 10px;
  padding-left: 25px;
  white-space: nowrap;
  padding-right: 25px;
  cursor: pointer;
  pointer-events: auto;
  transition: background-color 0.25s;

  &:hover {
    background-color: $c-red;
    box-shadow: 0 0 15px $c-gold;
  }
}

.exquisite {
  .button-black-padding {
    background-color: $c-tosca !important;
    border: 1px solid $c-white;
    color: $c-black;

    &:hover {
      background-color: $c-white !important;
      box-shadow: 0 0 15px $c-white;
      border: 1px solid $c-tosca;
    }
  }
}

.cosmogonie,
.cosmogonie.kids {
  .button-black-padding {
    background-color: $c-cream !important;
    border: 1px solid transparent;
    color: $c-black;

    &:hover {
      background-color: $c-cream !important;
      box-shadow: 0 0 15px $c-cream;
      border: 1px solid $c-black;
    }
  }
}

.eyewear {
  .button-black-padding {
    background-color: transparent;
    border: 1px solid $c-white;
    color: $c-white;
    display: inline-block;
    border-radius: 25px;

    &:hover {
      background-color: $c-white;
      color: $c-black;
      box-shadow: none;
      border-style: none;
    }
  }

  .button-circle-gold {
    border: 1px solid $c-white;

    &:hover {
      background-color: $c-white;
      box-shadow: none;
    }
  }
}

.twinsburg {
  .button-black-padding {
    position: relative;
    z-index: 1;
    background-color: $c-twinsburg-pale-yellow !important;
    border: 1px solid $c-black;
    color: $c-black;
    padding: 24px;

    &::before {
      pointer-events: none;
      content: '';
      position: absolute;
      z-index: -1;
      top: 3px;
      left: 3px;
      width: 100%;
      height: 100%;
      border-right: 2px solid $c-black;
      border-bottom: 2px solid $c-black;
    }

    &:hover {
      background-color: $c-cream !important;
      box-shadow: 0 0 15px $c-cream;
    }
  }
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dark-layer {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: $c-black-85;
}

.fixed-100vwvh {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.cosmogonie .transparent-black {
  background-color: rgba(18, 23, 43, 0.9);
  z-index: 2;
}

.transparent-black {
  background-color: rgba(0, 0, 0, 0.6);
  color: $c-white;
  z-index: 2;
}

.transparent-black-85 {
  background-color: rgba(0, 0, 0, 0.85);
  color: $c-white;
  z-index: 2;
}

.button-circle-gold {
  width: 50px;
  height: 50px;
  background-color: $c-black;
  border-radius: 25px;
  border: 3px solid $c-gold;
  position: relative;
  transition: background-color 0.5s;

  &:hover {
    background-color: $c-gold;
    box-shadow: 0 0 15px $c-gold;
  }
}

.kids .button-circle-yellow {
  background-color: $c-cream;
  border: 2px solid $c-white;

  path,
  circle {
    stroke: $c-cream;
    fill: $c-black;
  }

  &:hover {
    background-color: $c-white;
    box-shadow: 0 0 15px $c-cream;

    path,
    circle {
      stroke: $c-white;
      fill: $c-black;
    }
  }

}

.button-circle-yellow {
  width: 50px;
  height: 50px;
  background-color: $c-yellow;
  border-radius: 25px;
  border: 3px solid $c-white;
  position: relative;
  transition: background-color 0.5s;

  path {
    stroke: $c-white;
    fill: $c-white;
  }

  circle {
    stroke: $c-yellow;
    fill: $c-white;
  }

  &:hover {
    background-color: $c-white;
    box-shadow: 0 0 15px $c-yellow;

    path {
      stroke: $c-yellow;
      fill: $c-yellow;
    }

    circle {
      stroke: $c-white;
      fill: $c-yellow;
    }
  }

}

.cosmogonie .button-circle-red {
  background-color: $c-black;
  border: 2px solid $c-cream;

  path,
  circle {
    stroke: $c-black;
    fill: $c-cream;
  }

  &:hover {
    background-color: $c-black;
    box-shadow: 0 0 15px $c-cream;

    path,
    circle {
      stroke: $c-black;
      fill: $c-cream;
    }
  }

}

.button-circle-red {
  width: 50px;
  height: 50px;
  background-color: $c-red;
  border-radius: 25px;
  border: 3px solid $c-white;
  position: relative;
  transition: background-color 0.5s;

  path,
  circle {
    stroke: $c-red;
    fill: $c-white;
  }

  &:hover {
    background-color: $c-white;
    box-shadow: 0 0 15px $c-red;

    path,
    circle {
      stroke: $c-white;
      fill: $c-red;
    }
  }

}

.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.header {
  width: 100%;
  height: 50px;
  position: fixed;
  z-index: 6;
  top: 0;
  background-color: $c-black;
  text-align: center;

  svg {
    margin-top: 15px;
    height: 20px;
    width: 160px;
  }
}

.preloader {
  z-index: 10;
  background-color: $c-black-85;
  text-align: left;
  color: $c-white;

  .content {
    width: 30%;
    position: relative;
  }

  svg {
    width: 100%;
    height: auto;
  }

  &.eyewear {
    .content {
      width: 40%;
    }

    svg {
      width: 100%;
      height: auto;

      path {
        fill: transparent;
        stroke: $c-white;
        stroke-width: 0.5px;
      }
    }

    p {
      color: $c-white;
    }
  }

  &.exquisite {
    p {
      color: $c-tosca;
    }
  }

  &.cosmogonie {
    p {
      color: $c-cream;
    }
  }

  &.cosmogonie {
    .content {
      width: 25%;
    }

    p {
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      font-size: 30px;
    }
  }

  &.twinsburg {
    .text-outline {
      color: $c-twinsburg-pale-yellow;
      text-shadow: -1px 0 $c-black, 0 1px $c-black, 1px 0 $c-black, 0 -1px $c-black;
    }
  }

  p {
    font-size: 40px;
    font-weight: 600;
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 40px;
    color: $c-red;

    &::after {
      position: absolute;
      bottom: 20px;
      line-height: 0;
      content: '...';
      animation: dot 3s $ease-out-quint infinite;
    }
  }
}

.star-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.star-top {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.star {
  path {
    fill: $c-gold;
    stroke: $c-gold;
  }
}

.opacity-3 {
  opacity: 0.3;
}

.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.4s $ease-out-quint;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.scale-leave-active,
.scale-enter-active {
  transition: all 0.5s $ease-out-quint;
}

.scale-enter,
.scale-leave-to {
  border-radius: 100vw;
  transform: scale(0);
}

@keyframes dot {
  0% {
    content: '';
  }

  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }

  100% {
    content: '';
  }
}

@keyframes pulse {
  0% { transform: translate(-50%, -50%) scale(0.5);
    opacity: 0.9; }

  100% { transform: translate(-50%, -50%) scale(1);
    opacity: 0.4; }
}

</style>
