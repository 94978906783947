<template>
  <div class="floor-map"
    v-if="currentFloor"
    :class="[
      {'exquisite': $root.exquisite},
      {'cosmogonie': $root.cosmogonie},
      {'kids': $root.kids},
    ]"
    @click="getMapPoint">

    <transition name="slide-up">
      <div class="floor-container fixed-100vwvh" v-show="open">

        <div v-if="$root.exquisite" class="exquisite-title">
          <h3 class="uppercase light">Exquisite Gucci Map</h3>
          <p class="mb-6 visited">{{percentage}} {{$root.language[$route.params.language].percentVisited}}</p>
        </div>
        <div v-else-if="$root.cosmogonie" class="cosmogonie-title">
          <h3 class="uppercase light">Gucci Cosmogonie Map</h3>
          <p class="mb-0 visited">{{percentage}} {{$root.language[$route.params.language].percentVisited}}</p>
        </div>

        <div @click="onCloseMap"
          :class="$root.exquisite ? 'button-circle-yellow' : 'button-circle-gold'"
          class="pointer mt-3 close-button align-center">
          <iconClose class="svg-fill-white absolute-center"></iconClose><br>
          <!--<span>{{$root.language[$route.params.language].tour}}</span>-->
        </div>

        <div class="floor">

          <div class="map pa-3 pa-md-12 mb-6 mb-md-0" :class="{'kids': $root.kids}">
            <div class="map-content" ref="mapcontent">
              <img class="map-image" v-if="$root.exquisite" :src="mapExquisite">
              <showroomMap v-else-if="$root.cosmogonie && !$root.kids" class="floor-map main-map"></showroomMap>
              <showroomMapKids v-else class="floor-map main-map"></showroomMapKids>
              <!--<showroomMap v-if="$root.host === 'colorweb' && !$root.userIsColorwebRetail" class="floor-map main-map"></showroomMap>
              <showroomMapBE v-if="isLocalhost || $root.host === 'brandexperience'" class="floor-map main-map"></showroomMapBE>
              <showroomMapCWRetail v-if="$root.host === 'colorweb' && $root.userIsColorwebRetail" class="floor-map main-map"></showroomMapCWRetail>-->
              <div v-if="$root.cosmogonie && !$root.kids">
                <p class="room-name uppercase" :style="{left: '13%', top: '66%', transform: 'rotate(0deg)'}">EVENING</p>
                <p class="room-name uppercase" :style="{left: '-3%', top: '64%', transform: 'rotate(90deg)'}">SILK</p>
                <p class="room-name uppercase" :style="{left: '35%', top: '99%', transform: 'rotate(0deg)'}">READY TO WEAR WOMEN</p>
                <p class="room-name uppercase" :style="{left: '35%', top: '60%', transform: 'rotate(0deg)'}">READY TO WEAR MEN</p>
                <p class="room-name uppercase" :style="{left: '-5%', top: '85%', transform: 'rotate(90deg)'}">Jewels &<br>Watches</p>
              </div>
              <div v-else-if="$root.cosmogonie && $root.kids">
                <p class="room-name uppercase" :style="{left: '30%', top: '52%'}">BABY GIRL</p>
                <p class="room-name uppercase" :style="{left: '65%', top: '52%'}">BABY BOY</p>
                <p class="room-name uppercase" :style="{left: '47%', top: '10%'}">SHOES</p>
                <p class="room-name uppercase" :style="{left: '22%', top: '10%'}">HOLIDAY GIRL</p>
                <p class="room-name uppercase" :style="{left: '67%', top: '10%'}">HOLIDAY BOY</p>
                <p class="room-name uppercase" :style="{left: '-4%', top: '45%', transform: 'rotate(-90deg)'}">JUNIOR GIRL</p>
                <p class="room-name uppercase" :style="{left: '95%', top: '45%', transform: 'rotate(-90deg)'}">JUNIOR BOY</p>
              </div>

              <FloorMapDot
                :floor="currentFloor"
                :visitedRooms="$root.visitedRooms"
                @onRoomOver="onRoomOver"
                @onChangeRoom="onCloseMap"></FloorMapDot>
            </div>
          </div>
          <!--<div class="category pa-3 pa-md-12 pr-0 pr-md-0">
            <h3 class="extra-light mb-3 mb-md-6 mb-lg-12 uppercase">{{$root.language[$route.params.language].categories}}</h3>

            <div class="category-container" v-if="$root.BECategory && $root.BECategory.length > 0">
              <div v-for="(cat, index) in categories"
                class="link pointer"
                @click="onCategoryClick(cat.link)"
                :key="'cat'+index"
                target="_blank">{{cat.name}}</div>
            </div>
            <div class="category-container colorweb" v-else>
              <div v-for="(cat, index) in categories"
                class="link pointer"
                @click="onCategoryClick(cat)"
                @mouseover="currentHighlight = cat"
                @mouseout="currentHighlight = ''"
                :key="'cat'+index"
                target="_blank">{{$root.language[$route.params.language][cat]}}</div>
            </div>
          </div>-->
        </div>

      </div>
    </transition>

    <div v-show="!open"
      @click="$router.push({name: 'Room',params: {hotspotContent: 'map'}})"
      class="pointer mt-4 map-button  align-center"
      :class="$root.exquisite ? 'button-circle-yellow' : 'button-circle-gold'">
      <iconMap class="svg-fill-white absolute-center"></iconMap><br>
      <!--<span>{{$root.language[$route.params.language].map}}</span>-->
    </div>
  </div>
</template>

<script>
import FloorMapDot from '../atoms/FloorMapDot.vue'
import iconMap from '@/assets/svg/icon-map.svg'
import iconClose from '@/assets/svg/close.svg'
import ListFloor from '@/graphql/ListFloor.gql'
import showroomMap from '@/assets/svg/map-cosmogonie.svg'
import showroomMapKids from '@/assets/svg/map-cosmogonie-kids.svg'
import mapExquisite from '@/assets/img/map-exquisite.png'

export default {
  name: 'FloorMap',

  components: {
    iconMap, iconClose, FloorMapDot, showroomMap, showroomMapKids
  },

  data () {
    return {
      mapExquisite,
      open: false,
      currentFloor: null,
      currentHighlight: '',
      currentRoom: '',
      categories: []
    }
  },

  mounted () {
    this.highlight = []
    this.checkRoute()
    if (this.$root.BECategory && this.$root.BECategory.length > 0) {
      this.categories = this.$root.BECategory
    } else {
      this.categories = [
        'handbags',
        'luggage',
        'readytowear',
        'womenreadytowear',
        'shoes',
        'womenshoes',
        'silk',
        'jewellerywatch',
        'sunglasses'
      ]
    }
  },

  watch: {
    $route (newValue, oldValue) {
      this.checkRoute()
    }
  },

  computed: {
    isLocalhost () {
      return window.location.href.indexOf('localhost') > -1 // disable-eslint
    },
    percentage () {
      let perc = 0
      // console.log(this.$root.visitedRooms)
      if (this.$root.visitedRooms && this.$root.allRooms) {
        perc = ((this.$root.visitedRooms.length / this.$root.allRooms) * 100).toFixed(0)
      }
      return perc
    }
  },

  methods: {
    getMapPoint (e) {
      const rect = this.$refs.mapcontent.getBoundingClientRect()
      console.log(e, rect)
      const x = (e.clientX - rect.x) / rect.width * 100
      const y = (e.clientY - rect.y) / rect.height * 100
      console.log(x, y)
    },
    onRoomOver (room) {
      if (room) {
        this.currentRoom = room.toLowerCase()
      } else {
        this.currentRoom = ''
      }
    },
    onCategoryClick (link) {
      console.log(this.$route)

      if (window.firebase.analytics() && this.$root.host) {
        window.firebase.analytics().logEvent('Open_category', {
          category: link,
          host: this.$root.host
        })
        console.log('Firebase logEvent', window.firebase.analytics())
      }

      if (window.parent && this.$root.host === 'brandexperience') {
        const msg = {
          event: 'navigateToCategory',
          categoryId: link,
          originURL: window.location.href + '?host=' + this.$root.host,
          originPath: '#' + this.$route.path
        }
        console.log('Brand Experience POST MESSAGE', msg)
        window.parent.postMessage(msg, '*')
      } else {
        // window.open(link, '_blank')
        if (this.currentFloor.highlight) {
          for (let i = 0; i < this.currentFloor.highlight.length; i++) {
            const hl = this.currentFloor.highlight[i]

            if (hl.id === link) {
              this.$router.push(
                {
                  name: 'Room',
                  params: {
                    roomName: hl.room.room._slug
                  }
                }
              )
              this.onCloseMap()
            }
          }
        }
      }
    },
    onCloseMap () {
      this.open = false
      this.$emit('onMapClose')

      if (this.$route.params.hotspotContent && this.$route.params.hotspotContent === 'map') {
        this.$router.push(
          {
            name: 'Room',
            params: {}
          }
        )
      }
    },
    checkRoute () {
      if (this.$route.params.hotspotContent && this.$route.params.hotspotContent === 'map') {
        this.open = true
        this.$emit('onMapOpen')
      } else {
        this.open = false
        this.$emit('onMapClose')
      }
    }
  },
  apollo: {
    Floors: {
      query: ListFloor,
      skip () {
        return null
      },
      variables () {
        return {
          status: 'PUBLISHED',
          sortOn: 'id',
          descending: false
        }
      },
      async result () {
        if (this.Floors) {
          this.data = this.Floors

          for (let i = 0; i < this.data.items.length; i++) {
            const floor = this.data.items[i]

            if (floor._slug.indexOf('exquisite') > -1 && this.$root.exquisite && !this.$root.kids) {
              this.currentFloor = floor
            } else if (floor._slug === 'floor') {
              this.currentFloor = floor
            }
          }

          if (!this.currentFloor) {
            this.currentFloor = this.data.items[0]
          }
          console.log('floor', this.currentFloor)
          this.$emit('onFloorLoaded', this.currentFloor)
          if (this.$root.kids) {
            this.$root.allRooms = this.currentFloor.roomKids.length
          } else {
            this.$root.allRooms = this.currentFloor.rooms.length
          }
          // this.categories = this.currentFloor.categories
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ipad .portrait {
  .floor {
    flex-wrap: wrap;
  }

  .floor-map {
    height: auto;
    width: 95vw;
  }

  .map,
  .category {
    width: 100%;
  }

  .map {
    border-style: none;
  }

  .link {
    width: 25%;
    padding: 10px;
  }
}

.floor-container {
  background-color: $c-black-85;
  text-align: center;

  @include breakpoint('xs-only') {
    background-color: $c-white;
    overflow-y: scroll;
  }
}

.floor {
  display: inline-flex;
  flex-wrap: nowrap;
  position: absolute;
  width: 90%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  pointer-events: none;

  @include breakpoint('lg-and-down') {
    width: 100%;
  }

  @include breakpoint('xs-only') {
    width: 100%;
    position: relative;
    transform: none;
    top: 40px;
    left: 0;
    flex-wrap: wrap;
  }
}

.room-name {
  position: absolute;
  z-index: 2;
  // transform: translateX(-50%);
  white-space: nowrap;
  font-size: 14px;
  color: $c-gold;

  @include breakpoint('md-and-down') {
    font-size: 12px;
  }

  &.handbags {
    top: 15%;
    left: 32%;
  }

  &.luggage {
    top: 35%;
    left: 32%;
  }

  &.womenreadytowear {
    top: 81%;
    left: 32%;
  }

  &.readytowear {
    top: 45%;
    left: 32%;
  }

  &.silk {
    top: 60%;
    left: 11%;
    white-space: initial;
    width: 15%;
    line-height: 14px;
    transform: translateY(-100%);
  }

  &.womenshoes {
    top: 81%;
    left: 70%;
  }

  &.shoes {
    top: 53%;
    left: 70%;
  }

  &.jewellerywatch {
    top: 81%;
    left: 2%;
  }

  &.sunglasses {
    top: 46%;
    left: 2%;
  }

  &.multicolor {
    top: 38%;
    left: 60%;
  }
}

.map {
  margin: 0 auto;
  // border-right: 1px solid $c-black;

  @include breakpoint('xs-only') {
    width: 100%;
    border-style: none;
  }

  .map-content {
    position: relative;
    width: fit-content;
    height: fit-content;
  }
}

.map-image {
  width: auto;
  height: 90vh;
}

.floor-map {
  fill: none;
  stroke: $c-white;
  stroke-width: 1px;
  height: 65vh;
  width: auto;
  z-index: 1;

  g {
    stroke-width: 1px;
  }

  &.main-map {
    z-index: 1;
    position: relative;
  }

  &.highlight {
    position: absolute;
    left: 0;
    fill: $c-red;
    z-index: 0;

    path {
      fill: $c-red;
      stroke: none;
    }
  }
}

.category {
  width: 40%;
  position: relative;

  @include breakpoint('xs-only') {
    width: 100%;
  }
}

.category-container {
  width: 100%;

  .link {
    cursor: pointer;
    position: relative;
    padding: 20px;
    width: 50%;
    display: inline-block;
    vertical-align: top;

    @include breakpoint('md-and-down') {
      padding: 10px;
    }

    @include breakpoint('sm-and-down') {
      width: 100%;
      padding: 10px;
    }

    @include breakpoint('xs-only') {
      padding: 10px;
      width: 100%;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.close-button {
  position: fixed;
  top: 18px;
  right: 18px;

  @include breakpoint('xs-only') {
    top: 60px;
    right: 20px;
    position: relative;
    text-align: right;
    bottom: auto;
    left: auto;
  }
}

.slide-up-leave-active,
.slide-up-enter-active {
  transition: transform 1s $ease-out-quint;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100vh);
}

.cosmogonie {
  h3,
  .visited {
    text-align: left;
  }

  .floor-map {
    height: 90vh;
  }

  .close-button {
    position: fixed;
    top: 40px;
    right: 40px;

    svg path { //stylelint-disable-line
      stroke: $c-white;
      stroke-width: 1px;
    }
  }
}

.exquisite-title {
  position: fixed;
  bottom: 40px;
  left: 40px;
}

.cosmogonie-title {
  position: fixed;
  top: 40%;
  left: 40%;
  color: $c-cream;
}

.kids .cosmogonie-title {
  position: fixed;
  top: auto;
  bottom: 40px;
  left: 40px;
  color: $c-cream;
}
</style>
