<template>
  <div class="footer" v-if="$route.name === 'Room'" :class="{'map-open': mapOpen}">
    <div class="map inline-block" :class="{ 'mr-4': $root.userIsColorwebNoHotspot}">
      <FloorMap
        v-show="!$root.watches && !$root.eyewear"
        @onMapOpen="mapOpen = true"
        @onMapClose="mapOpen = false"
        @onFloorLoaded="onFloorLoaded"></FloorMap>
    </div>
    <!--<div class="timeline inline-block" :class="{'press': $root.userIsPress || $root.userIsColorwebNoHotspot}">
      <div class="timeline-content" v-if="rooms" v-show="!mapOpen && !isHotspotOpen">
        <div class="line"></div>
        <div class="line-active" :style="{width: (currentIndex * (100 / (rooms.length -1))) + '%'}"></div>
        <div class="place"
          v-for="(room, index) in rooms"
          v-show="room.roomName"
          @click="onPlaceClick(room)"
          :key="'room'+index"
          :class="{'active': index === currentIndex}"
          :style="{left: (index * (100 / (rooms.length -1))) + '%'}">
          <span v-show="room.roomName && room.roomName.enTitle && room.roomName.enTitle.toLowerCase() === 'handbags'" class="handbags uppercase">{{$root.language[$route.params.language].handbags}}</span>
          <span v-show="room.roomName && room.roomName.enTitle && room.roomName.enTitle.toLowerCase() === 'luggage'" class="luggage uppercase">{{$root.language[$route.params.language].luggage}}</span>
          <span v-show="room.roomName && room.roomName.enTitle && room.roomName.enTitle.toLowerCase() === 'readytowear'" class="readytowear uppercase">{{$root.language[$route.params.language].readytowear}}</span>
          <span v-show="room.roomName && room.roomName.enTitle && room.roomName.enTitle.toLowerCase() === 'womenreadytowear'" class="womenreadytowear uppercase">{{$root.language[$route.params.language].womenreadytowear}}</span>
          <span v-show="room.roomName && room.roomName.enTitle && room.roomName.enTitle.toLowerCase() === 'shoes'" class="shoes uppercase">{{$root.language[$route.params.language].shoes}}</span>
          <span v-show="room.roomName && room.roomName.enTitle && room.roomName.enTitle.toLowerCase() === 'womenshoes'" class="womenshoes uppercase">{{$root.language[$route.params.language].womenshoes}}</span>
          <span v-show="room.roomName && room.roomName.enTitle && room.roomName.enTitle.toLowerCase() === 'silk'" class="silk uppercase">{{$root.language[$route.params.language].silk}}</span>
          <span v-show="room.roomName && room.roomName.enTitle && room.roomName.enTitle.toLowerCase() === 'jewellery & watches'" class="jewellerywatch uppercase">{{$root.language[$route.params.language].jewellerywatch}}</span>
          <span v-show="room.roomName && room.roomName.enTitle && room.roomName.enTitle.toLowerCase() === 'sunglasses'" class="sunglasses uppercase">{{$root.language[$route.params.language].sunglasses}}</span>
          <span v-show="room.roomName && room.roomName.enTitle && room.roomName.enTitle.toLowerCase() === 'multicolor'" class="multicolor uppercase">MULTICOLOR</span>
        </div>
      </div>
    </div>-->
    <div v-if="!isHotspotOpen && !$root.userIsPress && !$root.userIsColorwebNoHotspot && !$root.cartIsLocked" class="mx-6 bag inline-block align-center">
      <ButtonWishlist></ButtonWishlist>
    </div>
  </div>
</template>

<script>
import ButtonWishlist from '../atoms/ButtonWishlist.vue'
import FloorMap from '../molecules/FloorMap.vue'
export default {
  name: 'Footer',
  data () {
    return {
      rooms: null,
      currentIndex: 0,
      mapOpen: false,
      isHotspotOpen: false
    }
  },

  components: {
    FloorMap, ButtonWishlist
  },

  mounted () {
    this.checkRoute()
  },
  // Query
  methods: {
    onFloorLoaded (floor) {
      console.log('floor loaded')
      this.rooms = []
      for (let i = 0; i < floor.rooms.length; i++) {
        const room = floor.rooms[i]
        if (room.roomName &&
          (!room.roomName.colorwebRetailOnly || (room.roomName.colorwebRetailOnly && this.$root.userIsColorwebRetail)) &&
          (!this.$root.watches || (this.$root.watches && room.roomName._slug.indexOf('jewels') > -1))) {
          this.rooms.push(room)
        }
      }
      // this.rooms = floor.rooms
    },
    onPlaceClick (room) {
      if (room.roomName._slug !== this.$route.params.roomName) {
        this.$router.push(
          {
            name: 'Room',
            params: {
              roomName: room.roomName._slug
            }
          }
        )
      }
    },
    checkRoute () {
      if (this.rooms) {
        for (let i = 0; i < this.rooms.length; i++) {
          const element = this.rooms[i]
          if (element.roomName && this.$route.params.roomName === element.roomName._slug) {
            this.currentIndex = i
          }
        }
      }

      if (this.$route.params.hotspotContent) {
        this.isHotspotOpen = true
      } else {
        this.isHotspotOpen = false
      }
    }
  },
  watch: {
    $route (newValue, oldValue) {
      this.checkRoute()
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  // width: 100%;
  height: 80px;
  top: 0;
  right: 0;
  // background-color: $c-white;
  z-index: 3;

  &.map-open {
    z-index: 4;
  }
}

.map {
  width: 50px;
  height: 100%;
}

.bag {
  width: 50px;
  height: 100%;
  position: relative;;
}

.timeline {
  width: calc(100% - 320px);
  position: relative;
  height: 100%;

  &.press {
    width: calc(100% - 240px);
  }
}

.timeline-content {
  width: 100%;
  margin-top: 40px;

  @include breakpoint('xs-only') {
    display: none;
  }

  .line {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid $c-black-10;
  }

  .line-active {
    position: absolute;
    border-bottom: 1px solid $c-black;
    transition: width 0.5s;
  }

  .place {
    position: absolute;
    cursor: pointer;

    &::after {
      width: 10px;
      height: 10px;
      content: '';
      position: absolute;
      margin-top: -5px;
      margin-left: -5px;
      background-color: transparent;
    }

    &::before {
      content: '';
      width: 1px;
      height: 10px;
      position: absolute;
      margin-top: -5px;
      background-color: $c-black;
    }

    span {
      display: none;
      position: absolute;
      transform: translate(-50%, -25px);
      white-space: nowrap;
    }

    &:hover,
    &.active {
      span {
        display: block;
      }
    }
  }
}
</style>
