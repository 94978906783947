<template>
  <div
    :class="$root.exquisite ? 'button-circle-red' : 'button-circle-gold'"
    class="mt-4 wishlist pointer button-circle-gold" @click="onWishlistClick">
    <iconLove v-if="$root.host === 'colorweb' && $root.exquisite" class="absolute-center svg-stroke-white svg-fill-black"></iconLove>
    <iconBag v-else-if="$root.host === 'colorweb'" class="absolute-center svg-stroke-white svg-fill-black"></iconBag>
    <iconCart v-else class="absolute-center svg-stroke-white svg-fill-black"></iconCart><br>
    <span class="number" :class="{'brandexperience': $root.host === 'brandexperience'}" v-if="article > 0">{{article}}</span>
    <!--<span v-if="$root.host === 'colorweb'">{{$root.language[$route.params.language].wishlist}}</span>
    <span v-else>{{$root.language[$route.params.language].bag}}</span>-->
  </div>
</template>

<script>
import iconLove from '@/assets/svg/icon-love.svg'

import iconBag from '@/assets/svg/icon-star.svg'
import iconCart from '@/assets/svg/icon-cart.svg'

export default {
  name: 'ButtonWishlist',

  components: {
    iconBag, iconCart, iconLove
  },

  props: {
    purple: {
      type: Boolean
    }
  },

  mounted () {
    this.$events.on('refreshCart', this.onRefreshCart)
    if (window.parent && this.$root.host === 'brandexperience' && !this.$root.userIsPress) {
      window.addEventListener('getCartArticles', this.onBECartArticles, false)
    }
  },

  computed: {
    article () {
      let num = this.$root.wishlist.length
      if (window.parent && this.$root.host === 'brandexperience') {
        for (let i = 0; i < this.$root.wishlist.length; i++) {
          const article = this.$root.wishlist[i]
          if (article.quantity && article.quantity > 1) {
            num += (article.quantity - 1)
          }
        }
      }
      return num
    }
  },
  methods: {
    onWishlistClick () {
      if (window.parent && this.$root.host === 'colorweb') {
        let oriURL = window.location.href
        console.log('url' + oriURL)
        if (oriURL.indexOf('?host=colorweb') === -1) {
          oriURL += '?host=colorweb'
        }

        if (oriURL.indexOf('token') === -1) {
          oriURL += '&token=' + this.$root.ColorWebToken
        }

        const msg = {
          event: 'navigateToWishlist',
          originURL: oriURL
        }
        console.log('Colorweb POST MESSAGE', msg)
        window.parent.postMessage(msg, '*')
      } else if (window.parent && this.$root.host === 'brandexperience') {
        const msg = {
          event: 'openCart'
        }
        console.log('Brand Experience POST MESSAGE', msg)
        window.parent.postMessage(msg, '*')
      }
    },
    onRefreshCart () {
      if (window.parent && this.$root.host === 'brandexperience' && !this.$root.userIsPress) {
        const msg = { event: 'getCartArticles' }
        console.log('Brand Experience POST MESSAGE', msg)
        window.parent.postMessage(msg, '*')
      }
    },

    onBECartArticles () {
      console.log(this.$root.wishlist)
    }
  }
}
</script>

<style lang="scss" scoped>
.wishlist {
  display: inline-block;
  position: relative;
  text-align: center;
}

.number {
  position: absolute;
  top: -4px;
  font-size: 9px;
  right: -4px;
  width: 20px;
  height: 20px;
  background: $c-black;
  border: 1px solid $c-white;
  border-radius: 15px;
  text-align: center;
  line-height: 18px;

  &.brandexperience {
    top: 26px;
  }
}

svg {
  path {
    stroke-width: 2px;
  }
}

span {
  text-align: center;
  display: block;
}
</style>
