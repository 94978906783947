<template>
  <div class="content-gallery"
    :class="[
      {'full': !mainPhotoExist},
      {'eyewear': $root.eyewear},
      {'exquisite': $root.exquisite},
      {'cosmogonie': $root.cosmogonie},
      {'kids': $root.kids}
    ]"
    :style="getStyle">
    <div class="total-set" v-if="isMultiple" :class="{'center': !mainPhotoExist}">
      <div
        v-for="index in totalSet"
        :key="'set' + index"
        class="product-set"
        :class="{'active': index - 1 === currentSetId}"
        @click="$emit('updateSet', index - 1)"></div>
    </div>
    <div class="gallery-wrapper"
      :style="{transform: 'translateX(' + galleryX + 'px)' }">
      <div class="item mr-6 mr-md-12 mb-2"
        v-for="(item,id) in content"
        :key="'item'+id"
        :ref="'item'+id">
        <transition name="fade-slide" appear>
          <div class="item-content" :style="{transitionDelay: ((id + 1) * 0.1) + 's'}">
            <div
              class="content pa-6 "
              v-if="(item.STMACO && $root.host === 'brandexperience' && beCodeExist(item.STMACO)) ||
                (item.STMACO && $root.host === 'colorweb' && active[id] === true) ||
                isLocalhost">
              <!--<div v-if="item.getImageId" class="img" :style="{'background-image': 'url(http://getimage.emea.guccigroup.dom/getimage/?Cod='+item.getImageId+')'}"></div>-->
              <div class="img" :style="{'background-image': 'url('+images[id]+')'}"></div>

              <div class="button add-remove "
                :class="[
                  {'pointer-none': disableButton},
                  {'brandexperience': $root.host === 'brandexperience'},
                  {'is-press': $root.host === 'brandexperience' && $root.userIsPress === true}
                ]"
                v-if="$root.host === 'colorweb' || (!$root.cartIsLocked && !$root.userIsPress && $root.host === 'brandexperience' && beCodeExist(item.STMACO))">
                <div v-if="!checkWishlist(item.STMACO)"
                  class="button-wishlist"
                  @click="addItem(item.STMACO)">
                  <span v-if="$root.host === 'colorweb'" class="mr-5 uppercase">{{$root.language[$route.params.language].addToWishlist}}</span>
                  <span v-else class="mr-5 uppercase">{{$root.language[$route.params.language].addToBag}}</span>
                  <div class="circle">
                    <!--<iconLove v-if="$root.host === 'colorweb' && !$root.exquisite" class="absolute-center svg-stroke-white svg-fill-white"></iconLove>-->
                    <iconBag v-if="$root.host === 'colorweb'" class="bag" :class="$root.kids ? 'svg-stroke-cream': 'svg-stroke-white'"></iconBag>
                    <iconCart v-else :class="$root.kids ? 'svg-stroke-cream': 'svg-stroke-white'"></iconCart>
                  </div>
                </div>
                <div v-if="checkWishlist(item.STMACO)" class="button-wishlist remove" @click="removeItem(item.STMACO)">
                  <span class="mr-5 uppercase">{{$root.language[$route.params.language].remove}}</span><iconBagRemove></iconBagRemove>
                </div>
              </div>

              <p class="mt-3 description" v-if="description[id]">{{description[id]}}</p>
              <div v-if="$root.host === 'colorweb' || ($root.host === 'brandexperience' && beCodeExist(item.STMACO))"
                @click="onItemClick(item.link, item.STMACO)"
                target="_blank"
                class="mt-6">
                <div
                  class="button-black-padding uppercase "
                  :class="[
                    {'brandexperience': $root.host === 'brandexperience'}
                  ]"
                >{{$root.language[$route.params.language].seeDetails}}</div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="nav mt-1 mt-md-3"
      v-show="(!smallView || isMultiple)"
      :class="[
        {'brandexperience': $root.host === 'brandexperience'},
        {'center': !mainPhotoExist}
      ]">
      <div @click="updateImage(-1)" class="arrow mr-6">
        <IconArrowLeft class="absolute-center svg-stroke-white"></IconArrowLeft>
      </div>
      <div @click="updateImage(1)" class="arrow">
        <IconArrowRight class="absolute-center svg-stroke-white"></IconArrowRight>
      </div>
    </div>

  </div>
</template>

<script>
import iconBag from '@/assets/svg/icon-bag.svg'
import iconCart from '@/assets/svg/icon-cart.svg'
// import iconLove from '@/assets/svg/icon-love.svg'

import iconBagRemove from '@/assets/svg/bag-remove.svg'
import IconArrowLeft from '@/assets/svg/arrow-left.svg'
import IconArrowRight from '@/assets/svg/arrow-right.svg'
import { Viewport } from '@monogrid/vue-lib'
import axios from 'axios'

export default {
  name: 'ContentGallery',

  mixins: [Viewport],

  components: {
    IconArrowRight, IconArrowLeft, iconBag, iconBagRemove, iconCart
  },

  props: {
    inputcontent: {
      type: Array,
      default: null
    },
    mainPhotoExist: {
      type: Boolean,
      default: true
    },
    mainPhotoWidth: {
      type: Number,
      default: 0
    },
    isMultiple: {
      type: Boolean
    },
    totalSet: {
      type: Number
    },
    currentSetId: {
      type: Number
    }
  },
  data () {
    return {
      content: [],
      disableButton: false,
      loaded: [],
      images: [],
      description: [],
      active: [],
      galleryX: 0,
      smallView: false
    }
  },

  watch: {
    viewPort (newValue, oldValue) {
      setTimeout(this.updateResize, 100)
    },
    inputcontent () {
      console.log('change content')
      this.loadContent()
    }
  },

  mounted () {
    this.loadContent()
  },

  computed: {
    getStyle () {
      let gallerystyle = {}

      gallerystyle = {
        width: (this.viewPort.width - this.mainPhotoWidth - 50) + 'px',
        left: (this.mainPhotoWidth + 50) + 'px'
      }

      return gallerystyle
    },
    isLocalhost () {
      // return true
      return window.location.href.indexOf('localhost') > -1
    }
  },
  methods: {
    beCodeExist (STMACO) {
      return (this.$root.BECode && this.$root.BECode[STMACO])
    },
    updateRoute () {
      if (!this.$route.params.photoindex || this.currentImage !== this.$route.params.photoindex) {
        this.$router.push({
          name: 'Room',
          params: {
            hotspotContent: this.$route.params.hotspotContent,
            photoindex: this.currentImage
          }
        }).catch(() => {})
      }
    },
    loadContent () {
      this.galleryX = 0
      this.loaded = []
      this.currentImage = 0
      this.content = []
      this.description = []
      this.active = []

      this.updateRoute()

      for (let i = 0; i < this.inputcontent.length; i++) {
        this.loaded[i] = false
        const item = this.inputcontent[i]
        if (this.$root.host === 'brandexperience' && this.beCodeExist(item.STMACO)) {
          this.description[i] = item.description.description
          this.content.push(item)
        }
      }
      if (window.parent && this.$root.host === 'colorweb') {
        this.loadColorwebDescription()
      } else {
        this.loadImage()
        if (window.parent && this.$root.host === 'brandexperience') {
          window.addEventListener('message', this.onBEMessage, false)
        }
      }
    },

    async loadColorwebDescription () {
      let STMACO = ''

      for (let i = 0; i < this.inputcontent.length; i++) {
        const item = this.inputcontent[i]
        STMACO += item.STMACO + '--'
        if (i < this.inputcontent.length - 1) {
          STMACO += ';'
        }
      }
      const params = "[{'TOKEN' :'" + this.$root.ColorWebToken + "' , 'STMACO':'" + STMACO + "', 'OP':'N'}]"
      const result = await axios.get('https://showroomtool.kering.net/ADAM-API-GUCCIS/api/wishlist?Key=' + params)

      console.log('colorweb description', result)
      for (let n = 0; n < result.data.length; n++) {
        const element = result.data[n]
        if (element.ACTIVE === true) {
          this.content.push({
            STMACO: element.STMACO,
            description: {
              description: element.NAMEXX,
              exclusive: 'colorweb'
            }
          })
          this.description.push(element.NAMEXX)
          this.active.push(element.ACTIVE)
        }
      }
      this.loadImage()
      console.log(this.active)
    },

    async loadImage () {
      this.images = []

      for (let i = 0; i < this.content.length; i++) {
        const item = this.content[i]

        const image = await this.$root.getImageItem(item.STMACO)
        this.$set(this.images, i, image)
        this.loaded[i] = true
      }

      if (this.$route.params.photoindex) {
        this.currentImage = parseInt(this.$route.params.photoindex)
      } else {
        this.updateRoute()
      }
      setTimeout(this.updateResize, 100)
    },

    onBEMessage (e) {
      console.log('Brand Experience RECEIVE MESSAGE', e.data.event ? e.data.event : '', e)
      if (e.data.event === 'responseAddArticleToCart' ||
        e.data.event === 'responseRemoveArticleFromCart' ||
        e.data.event === 'addArticleToCart' ||
        e.data.event === 'removeArticleFromCart') {
        this.disableButton = false
        if (!this.$root.userIsPress) {
          const msgArt = { event: 'getCartArticles' }
          console.log('Brand Experience POST MESSAGE', msgArt.event, msgArt)
          window.parent.postMessage(msgArt, '*')
        }
      }

      /* if (e.data.event === 'responseGetEventArticles') {
        console.log('BrandExperienceArticles')
        this.$root.BECode = e.data
        this.loadContent()
      } */
    },

    checkWishlist (STMACO) {
      let inWishlist = false

      if (window.parent && this.$root.host === 'brandexperience') {
        for (let i = 0; i < this.$root.wishlist.length; i++) {
          const item = this.$root.wishlist[i]

          if (item.smc === STMACO) {
            inWishlist = true
          }
        }
      } else {
        for (let i = 0; i < this.$root.wishlist.length; i++) {
          const item = this.$root.wishlist[i]
          const itemStamco = item.PRODXX + '-' + item.MATXXX + '-' + item.COLXXX

          if (STMACO.indexOf(itemStamco) > -1) {
            inWishlist = true
          }
        }
      }

      return inWishlist
    },

    onItemClick (link, STMACO) {
      if (window.parent && this.$root.host === 'brandexperience') {
        let oriURL = window.location.href
        if (oriURL.indexOf('?host=brandexperience') === -1) {
          oriURL += '?host=brandexperience'
        }

        if (this.$root.exquisite) {
          oriURL += '&exquisite=true'
        }

        let articleId = -1

        if (this.$root.BECode && this.$root.BECode[STMACO]) {
          articleId = this.$root.BECode[STMACO]
        }

        if (articleId !== -1) {
          const msg = {
            event: 'navigateToArticle',
            articleId: articleId,
            originURL: oriURL,
            originPath: '#' + this.$route.path
          }
          console.log('Brand Experience POST MESSAGE', msg)
          window.parent.postMessage(msg, '*')
        } else {
          console.log('BE Article not found', STMACO)
        }
      } else if (window.parent && this.$root.host === 'colorweb') {
        let oriURL = window.location.href
        if (oriURL.indexOf('?host=colorweb') === -1) {
          oriURL += '?host=colorweb&showroom=true'
        }

        if (this.$root.colorwebUserType) {
          oriURL += '&colorwebUserType=' + this.$root.colorwebUserType
        }

        if (oriURL.indexOf('token') === -1) {
          oriURL += '&token=' + this.$root.ColorWebToken
        }

        const msg = {
          event: 'navigateToProduct',
          STMACO: STMACO,
          originURL: oriURL
        }
        console.log('Colorweb POST MESSAGE', msg)
        window.parent.postMessage(msg, '*')
      }

      if (window.firebase.analytics() && this.$root.host) {
        window.firebase.analytics().logEvent('Open_product_details', {
          product_stmaco: STMACO,
          host: this.$root.host
        })
        console.log('Firebase logEvent', window.firebase.analytics())
      }
    },

    async addItem (STMACO) {
      this.$events.emit('addItem', STMACO)

      if (window.firebase.analytics() && this.$root.host) {
        window.firebase.analytics().logEvent('Add_to_wishlist', {
          product_stmaco: STMACO,
          host: this.$root.host
        })
        console.log('Firebase logEvent', window.firebase.analytics())
      }

      if (window.parent && this.$root.host === 'brandexperience') {
        let articleId = -1

        if (this.$root.BECode && this.$root.BECode[STMACO]) {
          articleId = this.$root.BECode[STMACO]
        }

        if (articleId !== -1) {
          this.disableButton = true
          const msg = { event: 'addArticleToCart', articleId: articleId }
          console.log('Brand Experience POST MESSAGE', msg)
          window.parent.postMessage(msg, '*')
        } else {
          console.log('BE Add Article not found', STMACO)
        }
      } else if (window.parent && this.$root.host === 'colorweb') {
        // const params = "[{'TOKEN' :'0b15728d-63aa-4edd-83f3-c98698761f00' , 'STMACO':'467702-X5Q59-5037--', 'OP':'I'}]"
        const params = "[{'TOKEN' :'" + this.$root.ColorWebToken + "' , 'STMACO':'" + STMACO + "--', 'OP':'I'}]"
        const result = await axios.get('https://showroomtool.kering.net/ADAM-API-GUCCIS/api/wishlist?Key=' + params)

        if (result.data && result.data.length > 0) {
          this.$root.wishlist = result.data
        }
      } else {
        if (!this.$root.wishlist.includes(STMACO)) {
          this.$root.wishlist.push(STMACO)
        }
      }
    },

    async removeItem (STMACO) {
      this.$events.emit('removeItem', STMACO)

      if (window.firebase.analytics() && this.$root.host) {
        window.firebase.analytics().logEvent('Remove_from_wishlist', {
          product_stmaco: STMACO,
          host: this.$root.host
        })
        console.log('Firebase logEvent', window.firebase.analytics())
      }

      if (window.parent && this.$root.host === 'brandexperience') {
        let articleId = -1

        if (this.$root.BECode && this.$root.BECode[STMACO]) {
          articleId = this.$root.BECode[STMACO]
        }

        if (articleId !== -1) {
          this.disableButton = true
          const msg = { event: 'removeArticleFromCart', articleId: articleId }
          console.log('Brand Experience POST MESSAGE', msg)
          window.parent.postMessage(msg, '*')
        } else {
          console.log('BE Add Article not found', STMACO)
        }
      } else if (window.parent && this.$root.host === 'colorweb') {
        // const params = "[{'TOKEN' :'0b15728d-63aa-4edd-83f3-c98698761f00' , 'STMACO':'467702-X5Q59-5037--', 'OP':'D'}]"
        const params = "[{'TOKEN' :'" + this.$root.ColorWebToken + "' , 'STMACO':'" + STMACO + "--', 'OP':'D'}]"
        const result = await axios.get('https://showroomtool.kering.net/ADAM-API-GUCCIS/api/wishlist?Key=' + params)

        if (result.data && result.data.length > 0) {
          this.$root.wishlist = result.data
        } else if (result.data && result.data.length === 0) {
          this.$root.wishlist = []
        }
      } else {
        const index = this.$root.wishlist.indexOf(STMACO)
        if (index > -1) {
          this.$root.wishlist.splice(index, 1)
        }
      }
    },

    updateImage (index) {
      this.currentImage += index

      if (this.smallView) {
        if (index > 0) {
          this.$emit('nextSet')
        } else {
          this.$emit('previousSet')
        }
      } else {
        if (this.currentImage < 0) {
          this.currentImage = 0
          this.$emit('previousSet')
        }

        if (this.currentImage > this.content.length - 1) {
          this.currentImage = this.content.length - 1
          this.$emit('nextSet')
        }

        this.updateRoute()
      }

      this.updateResize()
    },
    updateResize () {
      let tx = 0
      if (this.content.length > 0) {
        for (let i = 0; i < this.currentImage; i++) {
          const el = this.$refs['item' + i][0]
          // console.log(el)
          if (el) {
            tx -= (el.clientWidth + 48)
          }
        }

        let totalWidth = 0
        let totalWidthPositive = 0
        for (let i = 0; i < this.content.length; i++) {
          const el = this.$refs['item' + i][0]
          // console.log(el)
          if (el) {
            totalWidth -= (el.clientWidth + 48)
            totalWidthPositive += (el.clientWidth + 48)
          }
        }
        const containerWidth = (this.viewPort.width - this.mainPhotoWidth - 50)
        const lastItem = this.$refs['item' + (this.content.length - 1)][0]
        if (lastItem) {
          const limitLeft = totalWidth + containerWidth
          const limitLeftLast = limitLeft - lastItem.clientWidth

          if (totalWidthPositive < containerWidth * 1.1) {
            tx = (containerWidth - totalWidthPositive) / 2
            this.smallView = true
          } else {
            this.smallView = false
            if (tx < limitLeftLast) {
              this.$emit('nextSet')
            }

            // console.log('limit left', limitLeft)
            tx = tx < limitLeft ? limitLeft : tx
          }
        }
      }

      this.galleryX = tx
    }
  },

  created () {
    this.isAnimatingGallery = false
    if (!this.$root.platform.mobile) {
      document.addEventListener('wheel', (e) => {
        // console.log(e.deltaY)
        if (!this.isAnimatingGallery) {
          this.isAnimatingGallery = true
          if (e.deltaY > 0) {
            this.updateImage(1)
          } else {
            this.updateImage(-1)
          }
        }

        setTimeout(() => {
          this.isAnimatingGallery = false
        }, 1000)
      })
    } else {
      // mobile interaction
      document.addEventListener('touchstart', (e) => {
        this.xDown = e.touches[0].clientX
      }, false)
      document.addEventListener('touchmove', (e) => {
        if (!this.xDown) {
          return
        }

        if (!this.isAnimatingGallery) {
          const xUp = e.touches[0].clientX
          const xDiff = this.xDown - xUp

          this.isAnimatingGallery = true
          if (xDiff > 0) {
            /* left swipe */
            this.updateImage(1)
          } else {
            /* right swipe */
            this.updateImage(-1)
          }
          setTimeout(() => {
            this.isAnimatingGallery = false
          }, 500)
        }
      }, false)
    }
  },

  destroyed () {
    document.removeEventListener('wheel', null)
    window.removeEventListener('message', this.onBEMessage)
  }
}
</script>

<style lang="scss" scoped>
.cosmogonie {
  .nav .arrow {
    background-color: $c-black;
    border: 1px solid $c-cream;
    border-radius: 40px;

    &:hover {
      box-shadow: none;
      background-color: $c-cream;

      svg { //stylelint-disable-line
        path { //stylelint-disable-line
          stroke: $c-black;
        }
      }
    }

    svg { //stylelint-disable-line
      path { //stylelint-disable-line
        stroke: $c-cream;
      }
    }
  }

  .item-content {
    border: transparent;
    background: transparent;

    .content {
      background-color: $c-white;
    }
  }

  .button-wishlist,
  .add-remove.brandexperience .button-wishlist {
    background-color: $c-black !important;

    &:hover {
      background-color: $c-black !important;
    }
  }

  .product-set {
    border: 1px solid $c-cream;

    &.active {
      background-color: $c-cream;
    }
  }
}

.exquisite {
  .nav .arrow {
    background-color: $c-tosca;
    border: 1px solid $c-white;
    border-radius: 40px;

    &:hover {
      box-shadow: none;
      background-color: $c-white;

      svg { //stylelint-disable-line
        path { //stylelint-disable-line
          stroke: $c-tosca;
        }
      }
    }

    svg { //stylelint-disable-line
      path { //stylelint-disable-line
        stroke: $c-white;
      }
    }
  }

  .item-content {
    border: transparent;

    .content {
      background-color: $c-white;
    }
  }

  .button-wishlist,
  .add-remove.brandexperience .button-wishlist {
    background-color: $c-red-exq !important;

    &:hover {
      background-color: $c-red-exq !important;
    }
  }
}

.eyewear {
  .nav .arrow {
    background-color: transparent;
    border: 1px solid $c-black;

    &:hover {
      box-shadow: none;
      background-color: $c-black;

      svg { //stylelint-disable-line
        path { //stylelint-disable-line
          stroke: $c-white;
        }
      }
    }

    svg { //stylelint-disable-line
      path { //stylelint-disable-line
        stroke: $c-black;
      }
    }
  }

  .product-set {
    border: 1px solid $c-black;

    &.active {
      background-color: $c-black;
    }
  }

  .item-content {
    background-color: $c-white;

    &::before {
      background-color: transparent;
      border: 1px solid $c-black;
    }

    .content {
      background-color: $c-white;
    }
  }

  p {
    color: $c-black;
  }

  .button-black-padding {
    color: $c-black;
    border: 1px solid $c-black;

    &:hover {
      background-color: $c-black;
      color: $c-white;
    }
  }

  .button.add-remove {
    &:hover {
      width: 100%;

      .button-wishlist {
        background-color: transparent;
        box-shadow: none;
      }
    }

    .button-wishlist {
      background-color: transparent;
      border-style: none;

      span { //stylelint-disable-line
        color: $c-black;
      }

      .circle { //stylelint-disable-line
        &::before { //stylelint-disable-line
          content: ' ';
          width: 40px;
          height: 40px;
          transform: translate(-7px, -10px);
          border: 1px solid $c-black;
          border-radius: 20px;
          position: absolute;
        }
      }

      svg { //stylelint-disable-line

        path { //stylelint-disable-line
          stroke: $c-black;
        }
      }
    }
  }
}

.total-set.center {
  display: inline-block;
  margin-left: calc(50vw - 50px);
  transform: translateX(-50%);
}

.product-set {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 10px;
  border: 1px solid $c-white;
  margin: 10px 5px;
  pointer-events: all;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    background: $c-red;
    border-style: none;
  }
}

.portrait {
  @include breakpoint('sm-and-down') {
    .content-gallery {
      width: 50%;
      left: 50%;
    }

    .item-content .img {
      width: 38vw;
      height: 31vw;
    }
  }
}

.content-gallery {
  position: fixed;
  width: calc((100vw + 50px) - 100vh);
  left: calc(100vh - 50px);
  top: 50%;
  height: fit-content;
  transform: translate(0, -50%);
  transition: left 0.5s;
  overflow: hidden;

  @include breakpoint('md-and-down') {
    width: 45vw;
    left: 55vw;
    top: 50%;
  }

  @include breakpoint('xs-only') {
    position: relative;

    width: 100%;
    transform: none;
    top: auto;
  }

  &.full {
    width: 100%;
    left: 60px;
  }
}

.gallery-wrapper {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.5s $ease-out-quint;
}

.button-black-padding {
  &.brandexperience {
    background-color: $c-red;

    &:hover {
      background-color: $c-red-80;
    }
  }
}

.add-remove {
  &.is-press {
    display: none;
  }

  .button-wishlist {
    border: 1px solid $c-gold;
    transition: background-color 0.5s;
    background-color: $c-red !important;
  }

  &.brandexperience {
    .button-wishlist {
      background-color: $c-red !important;

      &:hover {
        background-color: $c-red-80 !important;
      }
    }
  }
}

.item-content {
  height: fit-content;
  background-color: $c-black;
  text-align: center;
  position: relative;
  height: 100%;
  border: 5px solid $c-gold;

  p {
    white-space: normal;
  }

  .img {
    height: calc(21vw);
    width: 21vw;
    background-size: contain;
    background-position: center center;

    @include breakpoint('sm-and-down') {
      height: calc(16vw);
      width: 16vw;
    }

    @include breakpoint('xs-only') {
      width: 100%;
      height: calc(100vw);
    }
  }

  .button {
    position: absolute;
    top: -3px;
    right: -3px;
    width: 71px;
    transition: all 0.5s;
    overflow: hidden;
    z-index: 2;
    cursor: pointer;
    pointer-events: all;

    &:hover {
      width: 100%;

      .button-wishlist {
        background-color: $c-red;
        box-shadow: 0 0 15px $c-red;

        span { // stylelint-disable
          opacity: 1;
          display: inline-block;
        }
      }
    }

    .button-wishlist {
      padding: 20px;
      background-color: $c-black;
      float: right;
      display: flex;

      span {
        opacity: 0;
        display: none;
      }
    }

    span {
      color: $c-white;
      height: 18px;
      margin-top: 3px;
    }

    svg {
      width: 28px;
      height: 18px;

      &.bag {
        width: 28px;
        height: 22px;
      }
    }
  }

  /* &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: $c-purple;
    position: absolute;
    transform: translate(5px, 5px);
    z-index: 0;
    top: 0;
    left: 0;

    @include breakpoint('xs-only') {
      display: none;
    }
  } */

  .content {
    z-index: 1;
    background-color: $c-black;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include breakpoint('xs-only') {
      width: 100vw;
    }
  }

  .description {
    margin: auto;
    font-size: 14px;
    line-height: 1.5;

    @include breakpoint('lg-and-down') {
      font-size: 12px;
      line-height: 1.4;
    }

    @include breakpoint('md-and-down') {
      font-size: 10px;
      line-height: 1.2;
    }
  }
}

.nav {
  display: inline-flex;
  flex-wrap: nowrap;

  @include breakpoint('xs-only') {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  &.center {
    margin-left: calc(50vw - 50px);
    transform: translateX(-50%);
  }

  .arrow {
    position: relative;
    width: 40px;
    height: 40px;
    padding: 10px;
    background-color: $c-black;
    border: 2px solid $c-gold;
    cursor: pointer;
    pointer-events: all;
    transition: background-color 0.5s;

    &:hover {
      background-color: $c-red;
      box-shadow: 0 0 15px $c-red;
    }
  }

  &.brandexperience {
    .arrow {
      background-color: $c-red;
      border-style: none;
    }
  }
}

.fade-slide-leave-active,
.fade-slide-enter-active {
  transition: opacity 1s $ease-out-quint, transform 1s $ease-out-quint;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0 !important;
  transform: translateY(50px) !important;
}
</style>
