var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hotspot || _vm.exit)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHidden && _vm.hotspot.room.backgroundImage360),expression:"!isHidden && hotspot.room.backgroundImage360"}],staticClass:"hotspot-room absolute-center",class:[
    {'exquisite': _vm.$root.exquisite},
    {'kids': _vm.$root.kids},
    {'cosmogonie': _vm.$root.cosmogonie},
    {'eyewear': _vm.$root.eyewear},
    {'arrow': _vm.isIcon('up') || _vm.isIcon('down') || _vm.isIcon('left') || _vm.isIcon('right')},
    {'direction': _vm.isIcon('next') || _vm.isIcon('prev') || _vm.isIcon('back')},
    {'inactive': !_vm.hotspot.room.backgroundImage360},
    {'no-border': _vm.isIcon('open') || _vm.isIcon('close') || _vm.isIcon('exit')}
  ],on:{"click":_vm.onClick}},[_c('div',{staticClass:"pointer button-open absolute-center"},[_c('div',[(_vm.isIcon('exit'))?_c('IconExit',{staticClass:"absolute-center svg-fill-black"}):(_vm.isIcon('close'))?_c('IconClose',{staticClass:"absolute-center svg-stroke-black"}):(_vm.isIcon('open'))?_c('IconOpen',{staticClass:"absolute-center svg-stroke-black"}):(_vm.isIcon('right'))?_c('IconArrowRight',{staticClass:"absolute-center svg-fill-white"}):(_vm.isIcon('left'))?_c('IconArrowLeft',{staticClass:"absolute-center svg-fill-white"}):(_vm.isIcon('down'))?_c('IconArrowDown',{staticClass:"absolute-center svg-fill-white"}):(_vm.isIcon('up'))?_c('IconArrowUp',{staticClass:"absolute-center svg-fill-white"}):_c('IconPov',{staticClass:"absolute-center svg-fill-black"}),_vm._v(" "),(_vm.isIcon('next'))?_c('p',[_vm._v("NEXT")]):_vm._e(),_vm._v(" "),(_vm.isIcon('prev') || _vm.isIcon('back'))?_c('p',[_vm._v("BACK")]):_vm._e(),_vm._v(" "),(_vm.isIcon('girl'))?_c('p',[_vm._v("GIRL")]):_vm._e(),_vm._v(" "),(_vm.isIcon('boy'))?_c('p',[_vm._v("BOY")]):_vm._e(),_vm._v(" "),(_vm.isIcon('baby'))?_c('p',[_vm._v("BABY")]):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }