<template>
  <div class="panolens">
    <div v-if="currentRoom" class="hotspot-reference">
      <div v-if="currentRoom.hotspotContentReference || currentRoom.hotspotContentWatchesReference">
        <div class="hotspot-info" v-for="(hotspot, index) in roomHotspotContent"
            :key="'hs'+index"
            :ref="'hotspot-info'+index"
          >

          <HotspotHtml :hotspotID="hotspot.hotspot._slug" :hotspot="hotspot"/>
        </div>
      </div>

      <div v-if="currentRoom.hotspotRoom">
        <div class="hotspot-room" v-for="(hotspot, index) in currentRoom.hotspotRoom"
            :key="'hr'+index"
            :ref="'hotspot-room'+index"
          >
          <HotspotRoomHtml
            :hotspot="hotspot"
            v-show="!hotspot.room.colorwebRetailOnly || (hotspot.room.colorwebRetailOnly && $root.userIsColorwebRetail)"
          />
        </div>
      </div>

      <div v-if="currentRoom.exitPosition">
        <div class="hotspot-room" :ref="'hotspot-exit'">
          <HotspotRoomHtml :exit="true"/>
        </div>
      </div>
    </div>

    <div class="pano-container" ref="pano" @click="$events.emit('onCloseHotspot')">
    </div>

    <div class="pano-hotspot-container" ref="panohotspot">
    </div>

    <!--<PanolensRoomList :currentRoom="currentRoom"></PanolensRoomList>-->
  </div>
</template>

<script>
import HotspotRoomHtml from '../atoms/HotspotRoomHtml.vue'

import { ImagePanorama, Viewer, InfospotSprite } from 'panolens/build/panolens.module'
import { Vector3 } from 'three'

import GetRoom from '@/graphql/GetRoom.gql'
import GetHotspotContent from '@/graphql/GetHotspotContent.gql'

import HotspotHtml from '@/atoms/HotspotHtml'
// import PanolensRoomList from '@/atoms/PanolensRoomList'
import { RAF } from '@monogrid/js-utils'
import { Viewport } from '@monogrid/vue-lib'
import infoCircle from '@/assets/img/info-circle.png'
import anime from 'animejs'
import slugify from 'slug'

export default {
  name: 'Panolens360',
  mixins: [Viewport],
  data () {
    return {
      rooms: null,
      roomslugs: [],
      currentRoom: null,
      camRotationY: 0,
      infoCircle,
      imagePano: '',
      isMapOpen: false,
      showTutorial: true,
      showExitPage: false,
      roomHotspotContent: []
    }
  },

  components: {
    // PanolensRoomList,
    HotspotHtml,
    HotspotRoomHtml
  },

  mounted () {
    this.infospots = []
    this.newpanorama = null
    this.currentPanorama = null
    this.zoomDefault = 85
    this.minZoom = 80
    this.maxZoom = 40
    this.fixedCamera = true

    RAF.add(this.update)
    this.$events.on('keepCameraSteady', () => { this.fixedCamera = true })
    this.$events.on('useCameraInitialPoint', () => { this.fixedCamera = false })
    this.$events.on('onOpenHotspot', (hotspot) => {
      if (this.viewer) {
        const ip = hotspot.position.split(',')
        this.viewer.tweenControlCenter(new Vector3(parseInt(ip[0]), parseInt(ip[1]), parseInt(ip[2])), 500)

        /* anime({
          targets: this,
          zoomDefault: 50,
          easing: 'easeInOutCirc',
          update: () => {
            this.viewer.setCameraFov(this.zoomDefault)
          }
        }) */
      }
    })

    this.$events.on('onCloseHotspot', () => {
      /* if (this.viewer) {
        anime({
          targets: this,
          zoomDefault: this.minZoom,
          easing: 'easeInOutCirc',
          update: () => {
            this.viewer.setCameraFov(this.zoomDefault)
          }
        })
      } */
    })
    // document.addEventListener('mousemove', this.onMouseUpdate)

    document.addEventListener('keyup', (e) => {
      // console.log(e.code)
      if (e.code === 'KeyA') {
        if (this.viewer.OrbitControls) {
          console.log('orbit control', this.viewer.OrbitControls.getAzimuthalAngle())
        }
      }

      if (e.code === 'KeyF') {
        if (this.viewer.OrbitControls) {
          this.viewer.OrbitControls.minPolarAngle = 0
          this.viewer.OrbitControls.minAzimuthAngle = -Infinity
          this.viewer.OrbitControls.maxAzimuthAngle = Infinity
        }
      }
    })
    // this.addZoomFunctionality()
  },

  beforeDestroy () {
    RAF.remove(this.update)
    // document.removeEventListener('mousemove', this.onMouseUpdate)
  },

  methods: {
    addZoomFunctionality () {
      document.addEventListener('wheel', (e) => {
        if (this.viewer && !this.$route.params.hotspotContent) {
          if (e.deltaY < 0) {
            if (this.zoomDefault > this.maxZoom) {
              this.zoomDefault--
              this.viewer.setCameraFov(this.zoomDefault)
            }
          } else {
            if (this.zoomDefault < this.minZoom) {
              this.zoomDefault++
              this.viewer.setCameraFov(this.zoomDefault)
            }
          }
        }
      })
      this.isTouching = false

      document.addEventListener('touchend', (e) => {
        if (this.viewer && this.isTouching && (!this.$route.params.hotspotContent)) {
          if (this.zoomDefault > 60) {
            anime({
              targets: this,
              zoomDefault: this.maxZoom,
              easing: 'easeInOutCirc',
              update: () => {
                this.viewer.setCameraFov(this.zoomDefault)
              }
            })
          } else {
            anime({
              targets: this,
              zoomDefault: this.minZoom,
              easing: 'easeInOutCirc',
              update: () => {
                this.viewer.setCameraFov(this.zoomDefault)
              }
            })
          }
        } else {
          this.isTouching = true
          setTimeout(() => {
            this.isTouching = false
          }, 200)
        }
      })
    },
    update () {
      if (this.viewer) {
        this.camRotationY = this.viewer.camera.rotation.y * (180 / Math.PI)
      }
    },

    /* onMouseUpdate (e) {
      this.isSmall = e.pageY > this.viewPort.height - 100 || e.pageY < 100
    }, */

    checkVideoPlay () {
      if (this.currentRoom.video360 && this.currentRoom.video360.id && this.currentPanorama.videoElement) {
        this.currentPanorama.videoElement.play()
      }
    },

    hotspotClick (hotspot) {
      // console.log('click', hotspot.title)
      this.viewer.autoRotate = false

      if (hotspot.linkToRoom || hotspot.exit) {
        this.cleanHotspot()

        let roomfloor = ''
        for (let i = 0; i < this.$root.floors.length; i++) {
          const floor = this.$root.floors[i]
          for (let n = 0; n < floor.rooms.length; n++) {
            const room = floor.rooms[n]
            if (room.roomName._slug === hotspot.linkToRoom._slug) {
              roomfloor = floor._slug
            }
          }
        }
        this.$router.push({
          name: 'Room',
          params: { floor: roomfloor, roomName: hotspot.linkToRoom._slug }
        })
      }
    },

    initPano () {
      this.infospots = []
      this.viewer = new Viewer({
        output: 'console',
        container: this.$refs.pano,
        controlBar: false,
        autoHideInfospot: false,
        autoRotate: false,
        autoRotateSpeed: 0.2,
        autoRotateActivationDuration: 20000
      })

      this.viewer.OrbitControls.noZoom = true

      const target = new Vector3(-100, -1000, 0)
      const { x, y, z } = target.normalize()
      this.viewer.camera.position.set(x, -y, -z)
      this.viewer.setCameraFov(this.zoomDefault)

      this.viewer.OrbitControls.minPolarAngle = Math.PI / 3
      // this.viewer.OrbitControls.maxPolarAngle = Math.PI * 2 / 3

      this.changePano()
    },

    async changePano () {
      // console.log(this.$refs)
      this.startPreload = false
      this.indexPreload = 0

      this.$events.emit('isLoadingStart')
      if (this.currentRoom) {
        const hasWebpSupport = window.Modernizr && !!window.Modernizr.webp // browser must support webp!

        const { backgroundImage360, backgroundImageWebp } = this.currentRoom

        const getImageURL = hasWebpSupport && backgroundImageWebp ? backgroundImageWebp : backgroundImage360
        this.imagePano = await this.$root.getImageShowroom('/media/' + getImageURL.id, getImageURL.originalName)

        this.newpanorama = new ImagePanorama(this.imagePano)
        this.newpanorama.addEventListener('progress', this.onPanoProgress)
        this.newpanorama.addEventListener('enter-fade-start', () => {
          let lookAtHotspot = null
          if (this.roomHotspotContent && this.$root.watches) {
            for (let i = 0; i < this.roomHotspotContent.length; i++) {
              const hs = this.roomHotspotContent[i]
              const hsslug = slugify(hs.hotspot._slug)
              if (hs.hotspot && this.$route.params.hotspotContent && hsslug === this.$route.params.hotspotContent) {
                lookAtHotspot = hs
              }
            }
          }

          this.fixedCamera = false

          if (this.viewer) {
            this.viewer.OrbitControls.minPolarAngle = Math.PI / 3

            if (lookAtHotspot !== null && this.$root.watches) {
              const hp = lookAtHotspot.position.position.split(',')
              this.viewer.tweenControlCenter(new Vector3(parseInt(hp[0]), parseInt(hp[1]), parseInt(hp[2])), 0)
            } else if (this.currentRoom.initialPoint) {
              const ip = this.currentRoom.initialPoint.split(',')
              this.viewer.tweenControlCenter(new Vector3(parseInt(ip[0]), parseInt(ip[1]), parseInt(ip[2])), 0)
            } else if (!this.fixedCamera) {
              this.viewer.tweenControlCenter(new Vector3(5000, 0, 0), 0)
            }

            if (this.currentRoom.limitPOV) {
              setTimeout(() => {
                const pv1 = parseFloat(this.currentRoom.limitPOV.split(',')[0])
                const pv2 = parseFloat(this.currentRoom.limitPOV.split(',')[1])

                if (pv1 < pv2) {
                  this.viewer.OrbitControls.minAzimuthAngle = pv1
                  this.viewer.OrbitControls.maxAzimuthAngle = pv2
                } else {
                  this.viewer.OrbitControls.minAzimuthAngle = pv2
                  this.viewer.OrbitControls.maxAzimuthAngle = pv1
                }
              }, 500)
            } else {
              this.viewer.OrbitControls.minAzimuthAngle = -Infinity
              this.viewer.OrbitControls.maxAzimuthAngle = Infinity
            }
          }
        })

        if (!this.currentPanorama) {
          const dummyHotspot1 = new InfospotSprite(0, this.infoCircle)
          const dummyHotspot2 = new InfospotSprite(0, this.infoCircle)
          this.newpanorama.add(dummyHotspot1)
          this.newpanorama.add(dummyHotspot2)
        }

        if (this.roomHotspotContent) {
          for (let i = 0; i < this.roomHotspotContent.length; i++) {
            const hs = this.roomHotspotContent[i]
            if (hs.position) {
              let panel = null
              if (this.$refs['hotspot-info' + i] && this.$refs['hotspot-info' + i][0]) {
                panel = this.$refs['hotspot-info' + i][0]
              }
              if (panel) {
                const infospot = new InfospotSprite(1, this.infoCircle)

                const pos = hs.position.position.split(',')
                infospot.position.set(pos[0], pos[1], pos[2])
                // infospot.position.set(0, -2000, -5000)
                infospot.addHoverElement(panel, 150, false, this.$refs.panohotspot)

                this.infospots.push(infospot)
                this.newpanorama.add(infospot)
              }
            }
          }
        }

        // Hotspot Change Room
        if (this.currentRoom.hotspotRoom) {
          for (let i = 0; i < this.currentRoom.hotspotRoom.length; i++) {
            const hs = this.currentRoom.hotspotRoom[i]
            if (hs.position) {
              let panel = null
              if (this.$refs['hotspot-room' + i] && this.$refs['hotspot-room' + i][0]) {
                if (!this.$root.watches || (this.$root.watches && hs.room._slug.indexOf('jewels') > -1)) {
                  panel = this.$refs['hotspot-room' + i][0]
                  const roomspot = new InfospotSprite(1, this.infoCircle)

                  const pos = hs.position.split(',')
                  roomspot.position.set(pos[0], pos[1], pos[2])

                  roomspot.addHoverElement(panel, 150, false, this.$refs.panohotspot)

                  this.infospots.push(roomspot)
                  this.newpanorama.add(roomspot)
                }
              }
            }
          }
        }

        // Hotspot Change Room
        if (this.currentRoom.exitPosition) {
          const exitspot = new InfospotSprite(1, this.infoCircle)

          const pos = this.currentRoom.exitPosition.split(',')
          exitspot.position.set(pos[0], pos[1], pos[2])
          const panel = this.$refs['hotspot-exit']
          exitspot.addHoverElement(panel, 150, false, this.$refs.panohotspot)

          this.infospots.push(exitspot)
          this.newpanorama.add(exitspot)
        }

        this.viewer.add(this.newpanorama)
        this.viewer.setPanorama(this.newpanorama)

        // if (this.currentRoom.video360 && this.currentRoom.video360.id) {
        // setTimeout(this.showPano, 1000)
        this.showPano()
        // }
      } else {
        this.$router.push({ name: 'Home' })
      }
    },

    onPanoProgress (event) {
      const percentage = event.progress.loaded / event.progress.total * 100
      // console.log(percentage)
      if (percentage >= 100) {
        this.showPano()
        // setTimeout(this.showPano, 1000)
      }
    },

    showPano () {
      setTimeout(() => {
        this.$events.emit('isLoadingEnd')
      }, 500)

      this.preloadNextPano()
      this.currentPanorama = this.newpanorama

      if (this.currentRoom.customZoom) {
        this.viewer.setCameraFov(this.currentRoom.customZoom)
      } else {
        this.viewer.setCameraFov(this.minZoom)
      }

      if (!this.$root.visitedRooms.includes(this.$route.params.roomName)) {
        this.$root.visitedRooms.push(this.$route.params.roomName)
        this.$root.updateCookie()
      }
    },

    async preloadNextPano () {
      this.startPreload = true
      if (this.currentRoom.hotspotRoom) {
        for (let i = 0; i < this.currentRoom.hotspotRoom.length; i++) {
          const hs = this.currentRoom.hotspotRoom[i]

          const room = await this.$apollo.query({
            query: GetRoom,
            variables: {
              slug: hs.room._slug
            }
          })

          // console.log(room)
          if (this.startPreload && room.data.Room) {
            const { backgroundImageWebp, backgroundImage360 } = room.data.Room
            if (backgroundImageWebp && backgroundImage360) {
              const hasWebpSupport = window.Modernizr && !!window.Modernizr.webp // browser must support webp!
              const getImageURL = hasWebpSupport && backgroundImageWebp ? backgroundImageWebp : backgroundImage360
              await this.$root.getImageShowroom('/media/' + getImageURL.id, getImageURL.originalName)
              // console.log('preloaded', getImageURL.originalName)
            }
          } else {
            return false
          }
        }
      }

      if (this.currentRoom.hotspotContent) {
        for (let i = 0; i < this.currentRoom.hotspotContent.length; i++) {
          const hc = this.currentRoom.hotspotContent[i].hotspot

          this.$apollo.query({
            query: GetHotspotContent,
            variables: {
              slug: hc._slug
            }
          })
        }
      }
    },

    cleanHotspot () {
      if (this.currentPanorama) {
        for (let i = 0; i < this.infospots.length; i++) {
          this.infospots[i].removeHoverElement()
          this.currentPanorama.remove(this.infospots[i])
          this.infospots[i].dispose()
        }
        this.infospots = []
      }

      if (this.$refs.panohotspot) {
        this.$refs.panohotspot.innerHTML = ''
      }
    },

    cleanPano () {
      if (this.currentPanorama) {
        this.viewer.remove(this.currentPanorama)
        this.currentPanorama.dispose()
      }
    },

    updateZoom (value) {
      if (this.viewer) {
        this.viewer.setCameraFov(this.zoomDefault)
      }
    }
  },

  watch: {
    '$apollo.loading': function (value) {
      if (value) {
        this.$events.emit('isLoadingStart')
      }
    }
  },

  apollo: {
    Room: {
      query: GetRoom,
      skip () {
        return !this.$route.params.roomName
      },
      variables () {
        return {
          slug: this.$route.params.roomName
        }
      },
      async result () {
        // console.log('Get Room result:', this.Room)
        if (this.Room && (this.Room.backgroundImage360 || this.Room.backgroundGetImage) && (!this.Room.panorama || this.Room.panorama.length === 0)) {
          if (!this.Room.colorwebRetailOnly || (this.Room.colorwebRetailOnly && this.$root.userIsColorwebRetail)) {
            if (!this.currentRoom || this.currentRoom.roomTitle !== this.Room.roomTitle) {
              this.cleanHotspot()
              this.currentRoom = this.Room

              if (this.$root.watches) {
                this.roomHotspotContent = this.currentRoom.hotspotContentWatchesReference
              } else {
                this.roomHotspotContent = this.currentRoom.hotspotContentReference
              }
              /* if (this.$root.host === 'brandexperience' && this.currentRoom.BEhotspotContent) {
                this.roomHotspotContent = this.currentRoom.BEhotspotContent
              } */

              if (window.firebase.analytics() && this.$root.host) {
                window.firebase.analytics().logEvent('Enter_room', {
                  roomName: this.Room.roomTitle,
                  host: this.$root.host
                })
                console.log('Firebase logEvent', window.firebase.analytics())
              }
              await this.$nextTick()

              if (!this.viewer) {
                this.initPano()
              } else {
                this.changePano()
              }
            }
          } else {
            this.$router.push(
              {
                name: 'Room',
                params: {
                  roomName: this.$root.allSettings.landingRoom._slug
                }
              }
            )
          }
        } else {
          this.currentRoom = null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.panorama-image {
  display: none;
}

.panolens {
  position: fixed;
  z-index: 0;
  overflow: hidden;

  width: 100vw;
  height: 100vh;

  transition: width 0.5s, height 0.5s, margin 0.5s;
}

.pano-container {
  width: 100vw;
  height: 100vh;
  transition: transform 0.5s;
  background-color: $c-white !important;
}

.pano-hotspot-container {
  position: absolute;
  top: 0;
  left: 0;
}

.hotspot-art,
.hotspot-info {
  position: relative;
}

.hotspot-reference {
  position: absolute;
  display: none;
}

.zoom-slider {
  position: fixed;
  top: 0;
  right: 0;
  background-color: $c-white;
}
</style>
