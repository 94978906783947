<template>
  <div
    v-show="show" class="hotspot-container absolute-center"
    :class="[
      {'cosmogonie': $root.cosmogonie},
      {'kids': $root.kids},
      {'exquisite': $root.exquisite},
      {'eyewear': $root.eyewear}
    ]"
    @click.stop="onClick">

    <div class="dot absolute-center">
      <IconPlus class="absolute-center"></IconPlus>
    </div>
  </div>
</template>

<script>

// import PlaySvg from '@/assets/svg/play.svg'
import { Viewport } from '@monogrid/vue-lib'
import GetHotspotContent from '@/graphql/GetHotspotContent.gql'
import axios from 'axios'
import IconPlus from '@/assets/svg/plus.svg'

export default {
  name: 'HotspotHtml',
  mixins: [Viewport],

  components: {
    IconPlus
  },

  props: {
    hotspot: {
      type: Object,
      required: true
    },
    hotspotID: {
      type: String,
      required: true
    }
  },

  mounted () {
    this.checkVisibility()
  },

  data () {
    return {
      show: false
    }
  },

  watch: {
    hotspotID (newValue, oldValue) {
      this.checkVisibility()
    }
  },
  methods: {
    async checkVisibility () {
      this.show = false
      if (this.hotspot.hotspot && !this.$root.userIsColorwebNoHotspot) {
        const hsslug = this.hotspotID
        const result = await this.$apollo.query({
          query: GetHotspotContent,
          variables: {
            slug: hsslug
          }
        })
        // console.log(result)
        if (result.data.HotspotContent) {
          const allSTMACO = []

          for (let n = 0; n < result.data.HotspotContent.products.length; n++) {
            const productSet = result.data.HotspotContent.products[n]
            for (let x = 0; x < productSet.product.length; x++) {
              const stamaco = productSet.product[x].STMACO
              if (stamaco) {
                allSTMACO.push(stamaco)
              }
            }
          }

          if (this.$root.host === 'colorweb') {
            let STMACO = ''

            for (let i = 0; i < allSTMACO.length; i++) {
              const item = allSTMACO[i]
              STMACO += item + '--'
              if (i < allSTMACO.length - 1) {
                STMACO += ';'
              }
            }
            const params = "[{'TOKEN' :'" + this.$root.ColorWebToken + "' , 'STMACO':'" + STMACO + "', 'OP':'N'}]"
            try {
              const result = await axios.get('https://showroomtool.kering.net/ADAM-API-GUCCIS/api/wishlist?Key=' + params)
              let productExist = false
              for (let n = 0; n < result.data.length; n++) {
                const element = result.data[n]
                if (element.ACTIVE === true) {
                  productExist = true
                }
              }
              this.show = productExist
            } catch (err) {

            }

            // to revert
            // this.show = true
          }
          if (this.$root.host === 'brandexperience') {
            let productExist = false
            for (let i = 0; i < allSTMACO.length; i++) {
              const item = allSTMACO[i]
              if (this.$root.BECode && this.$root.BECode[item]) {
                productExist = true
              }
            }
            this.show = productExist
          }
        }
      }
    },

    onClick () {
      if (window.firebase.analytics() && this.$root.host) {
        window.firebase.analytics().logEvent('Open_hotspot', {
          hotspot_id: this.hotspotID,
          host: this.$root.host
        })
        console.log('Firebase logEvent', window.firebase.analytics())
      }
      this.$events.emit('onOpenHotspot', this.hotspot.position)
      console.log(this.hotspotID)
      this.$router.push({
        name: 'Room',
        params: { hotspotContent: this.hotspotID }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.hotspot-container {
  position: absolute;
  transition: width 0.5s, height 0.5s;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;

  &::before {
    content: '';
    position: absolute;
    display: none;
    width: 55px;
    height: 55px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    visibility: initial;
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0.3);
    opacity: 0;
    border-radius: 60px;
    background-color: $c-red;
    // animation: pulse 3s $ease-out-quint infinite;
  }

  &:hover {
    .dot {
      width: 45px;
      height: 45px;
      border-radius: 30px;
      background-color: $c-gold;
      border-style: none;
      box-shadow: 0 0 15px $c-gold;
    }
  }

  &.eyewear {
    .dot {
      background-color: $c-white;
      border-style: none;
      width: 40px;
      height: 40px;
    }

    svg {
      path {
        fill: $c-black;
      }
    }

    &:hover {
      .dot {
        background-color: $c-white;
        box-shadow: 0 0 15px $c-white;
      }
    }
  }

  &.exquisite {
    transition: transform 0.5s;

    .dot {
      background-color: $c-red-exq;
      border: 3px solid $c-white;
    }

    svg {
      path {
        fill: $c-white;
      }
    }

    &:hover {
      transform: scale(1.4);

      .dot {
        background-color: $c-white;
        box-shadow: 0 0 15px $c-red-exq;
        border: 3px solid $c-red-exq;
      }

      svg {
        path { //stylelint-disable-line
          fill: $c-red-exq;
        }
      }
    }
  }

  &.cosmogonie {
    transition: transform 0.5s;

    .dot {
      background-color: $c-cream;
      border: 3px solid $c-white;
    }

    svg {
      path {
        fill: $c-black;
      }
    }

    &:hover {
      transform: scale(1.4);

      .dot {
        background-color: $c-white;
        box-shadow: 0 0 15px $c-cream;
        border: 3px solid $c-cream;
      }
    }
  }
}

.dot {
  width: 45px;
  height: 45px;
  background-color: $c-black;
  border-radius: 25px;
  border: 3px solid $c-gold;
  opacity: 0.8;
  transition: all 0.5s;

  @include breakpoint('sm-and-up') {
    &.open {
      display: none;
    }
  }

  svg {
    path {
      fill: $c-white;
    }
  }
}

</style>
