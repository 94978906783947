var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReady && _vm.allLoaded && _vm.isBECodeLoaded)?_c('v-app',{directives:[{name:"show",rawName:"v-show",value:(!_vm.platform.prerenderer),expression:"!platform.prerenderer"}],staticClass:"app",class:[
    {'cosmogonie' : _vm.$root.cosmogonie},
    {'twinsburg': _vm.$root.twinsburg},
    {'kids' : _vm.$root.kids},
    {'exquisite' : _vm.$root.exquisite},
    {'eyewear' : _vm.$root.eyewear},
    {'scroll' : _vm.isScroll},
    {'portrait' : _vm.viewPort.width < _vm.viewPort.height}
  ],attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.$root.host && _vm.isPano && !_vm.$root.twinsburg)?_c('div',[_c('Panolens360',{ref:"pano"}),_vm._v(" "),(_vm.$root.host && !_vm.$root.twinsburg)?_c('Panorama',{ref:"panorama"}):_vm._e(),_vm._v(" "),_c('Footer'),_vm._v(" "),_c('Tutorial')],1):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('router-view')],1),_vm._v(" "),_c('transition',{attrs:{"name":"scale"}},[(_vm.showHotspotContent && !_vm.$root.twinsburg)?_c('HotSpotOverlay'):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading)?_c('div',{staticClass:"preloader fixed-100vwvh",class:[
        {'twinsburg': _vm.$root.twinsburg},
        {'cosmogonie': _vm.$root.cosmogonie},
        {'kids': _vm.$root.kids},
        {'exquisite': _vm.$root.exquisite},
        {'eyewear': _vm.$root.eyewear}
      ]},[_c('div',{staticClass:"content absolute-center"},[(_vm.$root.twinsburg)?_c('iconTwinsburg',{staticClass:"welcome"}):_vm._e(),_vm._v(" "),(_vm.$root.cosmogonie)?_c('iconLoadingKids',{staticClass:"welcome"}):(_vm.$root.exquisite)?_c('iconExquisite',{staticClass:"welcome"}):_vm._e(),_vm._v(" "),_c('p',{staticClass:"text-outline uppercase"},[_vm._v("LOADING")])],1)]):_vm._e()]),_vm._v(" "),_c('RotateDevice')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }