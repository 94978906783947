<template>
  <transition name="fade-delay" appear>
    <div class="rooms" :class="[
      { 'exquisite' : $root.exquisite },
      { 'kids' : $root.kids },
      { 'cosmogonie' : $root.cosmogonie }
      ]">
      <div
        v-for="(room, rindex) in getRooms"
        :key="rindex"
        v-show="room.positionInMap && room.roomName"
        >
          <div class="room-container pointer"
            v-if="room.roomName && room.roomName.backgroundImage360 && (!room.roomName.colorwebRetailOnly || (room.roomName.colorwebRetailOnly && $root.userIsColorwebRetail))"
            @click.stop="onChangeRoom(floor._slug, room.roomName._slug)"
            @mouseover="$emit('onRoomOver', room.roomName.enTitle)"
            @mouseout="$emit('onRoomOver', '')"
            :class="{
              'visited': ($route.params.roomName !== room.roomName._slug && visitedRooms.includes(room.roomName._slug)),
              'active': ($route.params.roomName === room.roomName._slug),
              'main': room.positionInMap.indexOf('main') > -1
            }"
            :style="{
              'left': parsePosition(room.positionInMap).x + '%',
              'top': parsePosition(room.positionInMap).y + '%'
            }">
            <div class="room-icon"
              :class="{'has-content': hasContent(room.roomName)}">
              <IconPov v-if="($route.params.roomName === room.roomName._slug)" class="icon pov svg-fill-white absolute-center"></IconPov>
              <IconCheck v-else-if="visitedRooms.includes(room.roomName._slug) && ($route.params.roomName !== room.roomName._slug)" class="icon check svg-fill-brown absolute-center"></IconCheck>
              <!--<span>{{room.positionInMap}}</span>-->
            </div>

            <!--<p v-if="!parsePosition(room.positionInMap).type && room.roomName.enTitle" class="room-name uppercase letter-spacing-12">{{room.roomName.enTitle}}</p>-->
          </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconPov from '@/assets/svg/icon-map.svg'
import IconCheck from '@/assets/svg/check.svg'
import ListHotspotContent from '@/graphql/ListHotspotContent.gql'

export default {
  name: 'FloorMapDot',

  components: {
    IconPov, IconCheck
  },

  data () {
    return {
      hotspotBE: []
    }
  },
  props: {
    floor: {
      type: Object
    },
    visitedRooms: {
      type: Array
    }
  },

  computed: {
    getRooms () {
      return this.$root.kids ? this.floor.roomKids : this.floor.rooms
    }
  },
  apollo: {
    HotspotContents: {
      query: ListHotspotContent,
      skip () {
        return !this.$root.host || (this.$root.host && this.$root.host !== 'brandexperience')
      },
      variables () {
        return {
          status: 'PUBLISHED',
          sortOn: 'id',
          descending: false
        }
      },
      async result () {
        // console.log(this.HotspotContents)
        if (this.HotspotContents && ((this.$root.host && this.$root.host === 'brandexperience') || window.location.href.indexOf('localhost') > -1)) {
          for (let i = 0; i < this.HotspotContents.items.length; i++) {
            const hscontent = this.HotspotContents.items[i]

            let hasProduct = false

            for (let n = 0; n < hscontent.products.length; n++) {
              const productset = hscontent.products[n]

              for (let k = 0; k < productset.product.length; k++) {
                const STMACO = productset.product[k].STMACO
                if (this.$root.BECode && this.$root.BECode[STMACO]) {
                  hasProduct = true
                }
              }
            }

            if (hasProduct) {
              this.hotspotBE.push(hscontent._slug)
            }
          }
          // console.log(this.hotspotBE)
        }
      }
    }
  },

  mounted () {
  },
  methods: {
    hasContent (room) {
      let hasContent = false
      if (this.$root.host && this.$root.host === 'brandexperience') {
        if (room.hotspotContentReference) {
          for (let i = 0; i < room.hotspotContentReference.length; i++) {
            const hs = room.hotspotContentReference[i]
            if (this.hotspotBE.includes(hs.hotspot._slug)) {
              hasContent = true
            }
          }
        }
      }
      return hasContent
    },
    onChangeRoom (floor, room) {
      this.$emit('onChangeRoom')
      if (this.$route.params.roomName !== room) {
        this.$router.push(
          {
            name: 'Room',
            params: {
              roomName: room
            }
          }
        )
      }
    },
    parsePosition (posString) {
      return posString
        ? {
            x: posString.split(',')[0],
            y: posString.split(',')[1],
            type: posString.split(',')[2]
          }
        : { x: 0, y: 0 }
    }
  }
}
</script>

<style lang="scss" scoped>

.room-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 0;

  &.visited {
    border-style: none;

    .room-icon {
      border: 1px solid $c-red;
    }
  }

  &.active {
    z-index: 1;

    .room-icon {
      background-color: $c-black;
      border: 1px solid $c-red;

      width: 40px;
      height: 40px;

      @include breakpoint('md-and-down') {
        width: 25px;
        height: 25px;
      }

      @include breakpoint('xs-only') {
        width: 20px;
        height: 20px;
      }

      path {
        fill: $c-red;
      }
    }
  }

  &:hover {
    z-index: 1;

    .room-name {
      display: block;
    }
  }
}

.main {
  .room-icon {
    background-color: $c-red;
    width: 30px;
    height: 30px;

    @include breakpoint('md-and-down') {
      width: 22px;
      height: 22px;
    }

    @include breakpoint('xs-only') {
      width: 18px;
      height: 18px;
    }
  }
}

.exquisite {
  .active {
    z-index: 1;

    .room-icon {
      background-color: $c-yellow;
      border: 1px solid $c-yellow;

      path, circle { // stylelint-disable-line
        fill: $c-white;
        stroke: $c-yellow;
      }
    }
  }

  .visited {
    border-style: none;

    .room-icon {
      background-color: $c-yellow;
      border: 1px solid $c-yellow;

      path { // stylelint-disable-line
        fill: $c-white;
      }
    }
  }

  .room-icon {
    background-color: $c-white;
    border: 1px solid $c-yellow;

    &:hover {
      background-color: $c-yellow;
    }
  }

}

.cosmogonie {
  .active {
    z-index: 1;

    .room-icon {
      background-color: $c-cream;
      border: 1px solid $c-white;

      path, circle { // stylelint-disable-line
        fill: $c-black;
        stroke: $c-cream;
      }
    }
  }

  .visited {
    border-style: none;

    .room-icon {
      background-color: $c-cream;
      border: 1px solid $c-white;

      path { // stylelint-disable-line
        fill: $c-black;
      }
    }
  }

  .room-icon {
    background-color: $c-cream;
    border: 1px solid $c-cream;

    &:hover {
      background-color: $c-cream;
    }
  }

}

.room-icon {
  width: 18px;
  height: 18px;
  padding: 3px;
  border: 1px solid $c-gold;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;

  @include breakpoint('lg-and-down') {
    padding-left: 4px;
    width: 15px;
    height: 15px;
  }

  @include breakpoint('md-and-down') {
    padding-left: 7px;
    width: 12px;
    height: 12px;
  }

  &:hover {
    border: 1px solid $c-gold;
    width: 18px;
    height: 18px;
  }

  &.has-content {
    border: 1px solid $c-red;

    .check {
      stroke: $c-white !important;
      fill: $c-white !important;

      path {
        stroke: $c-white !important;
        fill: $c-white !important;
      }
    }
  }

  .icon {
    width: 12px;
    height: 12px;

    @include breakpoint('md-and-down') {
      width: 10px;
      height: 12px;
    }
  }
}

.room {
  position: absolute;
  background-color: #ccc;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.room-name {
  width: 100px;
  text-align: center;
  position: absolute;
  top: 24px;
  display: none;
  font-size: 10px;

  @include breakpoint('sm-and-down') {
    font-size: 10px;
    line-height: 12px;
  }
}

.fade-delay-leave-active,
.fade-delay-enter-active {
  transition: opacity 0.5s 0.5s $ease-out-quint;
}

.fade-delay-enter,
.fade-delay-leave-to {
  opacity: 0 !important;
}

</style>
